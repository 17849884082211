import React, { useEffect, useReducer, useState } from 'react'
import { useDirectus } from 'react-directus';
import { Textfield, Checkboxes } from '@components'
import { useTranslation } from 'react-i18next';
import { useError } from '@context/ErrorContext';
import { GeneralButtonGroup } from '../../../../components';
import Textarea from '../../../../components/input-fields/textfield/Textarea';

const initialState = {}

const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_VALUE':
            return {
                ...state,
                [action.key]: action.payload
            }
        case 'UPDATE_STATE':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export default function EditWorkshop(props) {
    const { id, createWorkshop, hub, anmeldungId, handleModalClose } = props;
    const { t } = useTranslation('anmeldung'); // Initialize the translation hook
    const { setErrorMessage, setSuccessMessage } = useError();

    const [state, dispatch] = useReducer(reducer, initialState);

    const { directus } = useDirectus();

    const [historieValue, setHistorieValue] = useState('');
    const [historyData, setHistoryData] = useState([]);


    const handleSave = () => {
        if (createWorkshop) {
            createNewWorkshop(state);
        } else {
            updateWorkshop(state);

        }
    }

    const options =

        [
            {
                name: t('unterstufe_12'),
            },
            {
                name: t('unterstufe_34'),
            },
            {
                name: t('unterstufe_56'),
            },
            {
                name: t('oberstufe_1'),
            },
            {
                name: t('oberstufe_2'),
            },
            {
                name: t('oberstufe_3'),
            },
        ];
    useEffect(() => {

        if (id !== undefined) {
            directus.items('workshops').readOne(id).then(
                (response) => {


                    const selectedFields = {
                        id: response.id,
                        anzahl_schueler: response.anzahl_schueler,
                        bezeichnung: response.bezeichnung,
                        email_lehrperson: response.email_lehrperson,
                        inklusion: response.inklusion,
                        lehrperson: response.lehrperson,
                        stufe: response.stufe,
                    }

                    dispatch({
                        type: 'UPDATE_STATE',
                        payload: selectedFields
                    })


                }).catch((e) => {
                    setErrorMessage(t('Fehler beim Laden der Daten'), e);
                })
        }

    }, [])


    const updateWorkshop = async (item) => {
        if (historieValue === '') {
            setErrorMessage(t('Bitte einen Kommentar für die Historie eingeben'));
            return;
        }

        try {
            await directus.items('workshops').updateOne(item.id, {
                bezeichnung: item.bezeichnung,
                lehrperson: item.lehrperson,
                email_lehrperson: item.email_lehrperson,
                stufe: item.stufe,
                inklusion: item.inklusion,
                anzahl_schueler: item.anzahl_schueler
            });
            setSuccessMessage(t('Daten erfolgreich gespeichert'));
            handleModalClose(false);

        } catch (error) {
            setErrorMessage('Fehler beim Speichern der Daten', error);
        }

        console.log('historyData', historyData)
        const newHistory = historyData !== null ? historyData : [];
        newHistory.push({ date: new Date(), message: `Workshop bearbeitet: ${historieValue}` });

        directus.items('anmeldungen').updateOne(anmeldungId, {
            history: JSON.stringify(newHistory)
        }).then((response) => {

        }).catch((e) => {
        })
    };


    const createNewWorkshop = async (item) => {
        if (historieValue === '') {
            setErrorMessage(t('Bitte einen Kommentar für die Historie eingeben'));
            return;
        }

        try {
            await directus.items('workshops').createOne({
                bezeichnung: item.bezeichnung,
                lehrperson: item.lehrperson,
                email_lehrperson: item.email_lehrperson,
                stufe: item.stufe,
                inklusion: item.inklusion,
                anzahl_schueler: item.anzahl_schueler,
                anmeldung: anmeldungId,
                hub: hub
            });
            setSuccessMessage(t('Daten erfolgreich gespeichert'));
            handleModalClose(false);

        } catch (error) {
            setErrorMessage(t('Fehler beim Speichern der Daten'), error);
        }

        const newHistory = historyData;
        newHistory.push({ date: new Date(), message: `Workshop erstellt: ${historieValue}` });

        directus.items('anmeldungen').updateOne(anmeldungId, {
            history: JSON.stringify(newHistory)
        }).then((response) => {

        }).catch((e) => {
        })
    };

    const handleDelete = async () => {
        if (historieValue === '') {
            setErrorMessage(t('Bitte einen Kommentar für die Historie eingeben'));
            return;
        }

        try {
            await directus.items('workshops').deleteOne(id);
            setSuccessMessage(t('Workshop erfolgreich gelöscht'));
            handleModalClose(false);
        } catch (error) {
            setErrorMessage(t('Fehler beim Löschen des Workshops'), error);
        }

        const newHistory = historyData;
        newHistory.push({ date: new Date(), message: `Workshop gelöscht: ${historieValue}` });

        directus.items('anmeldungen').updateOne(anmeldungId, {
            history: JSON.stringify(newHistory)
        }).then((response) => {

        }).catch((e) => {
        })
    }


    useEffect(() => {

        console.log('id', anmeldungId)

        if (anmeldungId !== undefined) {
            directus.items('anmeldungen').readOne(anmeldungId, { fields: ['history'] }).then(
                (response) => {
                    const fetchedData = response;

                    const tmpHistoryData = fetchedData?.history !== undefined ? JSON.parse(fetchedData?.history) : [];

                    console.log('tmpHistoryData', fetchedData)

                    setHistoryData(tmpHistoryData);

                }).catch((e) => {
                    setErrorMessage(t('Fehler beim Laden der Daten'), e);
                })
        }

    }, [])

    return (
        <div className="general-inputs">
            <h4>{t("Allgemeine Infos")}</h4>
            <Textfield
                className="small"
                value={state['bezeichnung']}
                onChange={(value) =>
                    dispatch({
                        type: 'UPDATE_VALUE',
                        payload: value,
                        key: 'bezeichnung',
                    })
                }
                placeholder={'Bezeichnung'}
                key='bezeichnung'
            />
            <Textfield
                className="small"
                value={state['lehrperson']}
                onChange={(value) =>
                    dispatch({
                        type: 'UPDATE_VALUE',
                        payload: value,
                        key: 'lehrperson',
                    })
                }
                placeholder={'Lehrperson'}
                key='lehrperson'
            />
            <Textfield
                className="small"
                value={state['email_lehrperson']}
                onChange={(value) =>
                    dispatch({
                        type: 'UPDATE_VALUE',
                        payload: value,
                        key: 'email_lehrperson',
                    })
                }
                placeholder={'Email Lehrperson'}
                key='email_lehrperson'
            />
            <Textfield
                className="small"
                value={state.anzahl_schueler || ''}
                onChange={(value) =>
                    dispatch({
                        type: 'UPDATE_VALUE',
                        payload: value,
                        key: 'anzahl_schueler'
                    })
                }
                placeholder={t('anzahl_sus')}
                key={'anzahl_schueler'}
                type={'number'}
                min="1"
            />
            <h4>{t("Stufe")}</h4>
            <Checkboxes
                options={options}
                items={state?.stufe || ''}
                dispatch={(value) =>
                    dispatch({
                        type: 'UPDATE_VALUE',
                        payload: value,
                        key: 'stufe'
                    })}
                key={'stufe'}
                group={'stufe'}
            />
            <h4>{t("Inklusion")}</h4>
            <input
                type="checkbox"
                id="inklusion"
                name="accept-general-terms"
                checked={state?.inklusion}
                onChange={(event) =>
                    dispatch({
                        type: 'UPDATE_VALUE',
                        payload: event.target.checked,
                        key: 'inklusion',

                    })}
            />

            <label htmlFor="inklusion">{t('inklusion')}</label>
            <h4>{t("Historie")}</h4>
            <Textarea
                value={historieValue || ''}
                onChange={(value) =>
                    setHistorieValue(value)}
                placeholder={'Kommentar für die Historie'}
                key={'history'} />

            <GeneralButtonGroup buttons={[{ text: 'Speichern', classNames: 'button--inline', handleOnClick: () => handleSave() }, { text: 'Löschen', classNames: 'button--inline button--delete', handleOnClick: () => handleDelete() }]} />
        </div>
    )
}

EditWorkshop.defaultProps = {
    id: undefined,
    createWorkshop: false
};