const createUpdateObject = (itemsToBeCreated, itemsToBeRemoved, anmeldungId) => {
    const createArray = itemsToBeCreated.map(slotId => {
        return {
            anmeldungen_id: parseInt(anmeldungId, 10),
            slots_id: { id: slotId }
        };
    });

    return {
        create: createArray,
        update: [],
        delete: itemsToBeRemoved
    };
};

const sortSlots = (slots) => {
    slots.sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;

        return zeitOrder[a.zeit] - zeitOrder[b.zeit];
    });

    return slots;
}

const zeitOrder = {
    "vormittag": 1,
    "nachmittag": 2
};

export { createUpdateObject, sortSlots };