import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import PropTypes from 'prop-types';

export default function MapPreview({ geojson, position, location }) {
    const mapRef = useRef(null);

    useEffect(() => {

        if (!location) return;


        const map = L.map(mapRef.current,
            {
                zoomControl: false, // Disable the default zoom control (buttons)
                scrollWheelZoom: false, // Disable zooming with the mouse wheel
            }).setView(location, 18);
        L.Icon.Default.imagePath = '/assets/';

        const zoom = L.control.zoom();
        zoom.addTo(map);

        L.tileLayer('https://wmts100.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg', {
            maxZoom: 20
        }).addTo(map);
        const featureGroup = L.featureGroup().addTo(map);

        let geojsonLayer;
        let geojsonBounds;

        if (geojson) {
            geojsonLayer = L.geoJSON(geojson).addTo(featureGroup);
            geojsonBounds = geojsonLayer.getBounds();
        }

        if (position) {
            const [longitude, latitude] = position.geometry.coordinates;
            L.marker([latitude, longitude]).addTo(featureGroup);

            const markerLatLng = L.latLng(latitude, longitude);


            if (geojsonBounds) {
                geojsonBounds.extend(markerLatLng);
                map.fitBounds(geojsonBounds);
            } else {
                map.setView(markerLatLng, 16);
            }
        } else if (geojsonBounds) {
            map.fitBounds(geojsonBounds);
        }

        return () => {
            map.remove();
        };
    }, [location, geojson, position]);

    return <div ref={mapRef} style={{ height: '400px' }} />;
}

MapPreview.propTypes = {
    geojson: PropTypes.object,
    position: PropTypes.object,
    location: PropTypes.array,
};