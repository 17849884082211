import { Entry } from '@helpers/Entries';

class NewsletterEntries {
    constructor(rawData) {
        this.rawData = rawData;

        this.flattenedData = rawData.map((obj) => {
            return {
                ...obj
            };
        });

        this.attributes = NewsletterObject.getAttributes();

    }

    getData() {
        return this.flattenedData;
    }

    getTableColumns(filter) {
        if (filter === undefined) {
            return this.attributes;
        }

        return this.attributes.filter((item) => {
            return filter.includes(item.key);
        });
    }
}

const NewsletterObject = new Entry([
    { key: 'vorname', name: 'Vorname' },
    { key: 'nachname', name: 'Nachname' },
    { key: 'email', name: 'E-Mail' },
    { key: 'language', name: 'Sprache' }
]);

export { NewsletterEntries, NewsletterObject };