import { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDirectus } from 'react-directus';

import { Textfield, Button } from '../../components';


import './login-form.scss';
import { useTranslation } from 'react-i18next';


const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [tfa, setTFA] = useState('');

    const [twoFaRequired, setTwoFaRequired] = useState(false);

    const [err, setErr] = useState(null);

    const navigate = useNavigate();
    const { directus } = useDirectus();
    const { t } = useTranslation('login');

    const checkTfa = async () => {
        try {

            const newMe = await directus.users.me.read({ fields: ['*', 'role.*'] });
            if (newMe.tfa_secret === null && newMe.role.enforce_tfa) navigate('/enforce-tfa');
        } catch (error) {
            console.log(error)
        }

    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const loginParams = { email: email, password: password, mode: "cookie" };

        if (tfa) {
            loginParams.otp = tfa;
        }
        directus.auth.login(loginParams).then(
            (response) => {
                console.log(response);
                checkTfa();
                navigate('/app/dashboard');
            }).catch((error) => {
                console.log(error.message === 'Invalid user OTP.');
                console.log(error);

                if (error.message === 'Invalid user OTP.') {
                    setTwoFaRequired(true);
                } else {
                    setErr(error.message);
                }
            });



    }

    return <div className='login-form'>
        <h1>{t('Login')}</h1>
        <form onSubmit={e => { handleSubmit(e) }}>

            {twoFaRequired ? <>
                <Textfield value={email} onChange={setEmail} placeholder={t('Email')} />
                <Textfield value={password} onChange={setPassword} placeholder={t('Passwort')} type="password" />
                <Textfield value={tfa} onChange={setTFA} placeholder={t('One Time Passwort')} />
            </>
                :
                <>
                    <Textfield value={email} onChange={setEmail} placeholder={t('Email')} />
                    <Textfield value={password} onChange={setPassword} placeholder={t('Passwort')} type="password" />
                </>}



            {err ? <div className="error-message">{err}</div> : null}

            <Button
                type='submit'
                value={t('Login')}
            />
            <Link className="reset-password-link" to="/password-reset">{t('Passwort vergessen')}</Link>
        </form>

    </div>;
};

export default LoginForm;