import React, { useEffect, useState } from 'react'
import { useDirectus } from 'react-directus';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

export default function HubPreview(props) {
    const { hub } = props;
    const { directus } = useDirectus();
    const [slots, setSlots] = useState(null);
    const [workshops, setWorkshops] = useState(null);
    const [anmeldungen, setAnmeldungen] = useState(null);
    const { t } = useTranslation('dashboard');

    useEffect(() => {

        directus.items('slots').readByQuery({ limit: 1000, filter: { hub: { _eq: hub.id } } }).then(
            (response) => {
                // Access the 'data' property of the response and use 'length' to get the number of slots
                const number = response.data.length;
                setSlots(number);
            }).catch((e) => {
                console.log(e);
            })

        directus.items('anmeldungen').readByQuery({ limit: 1000, filter: { hub: { _eq: hub.id } } }).then(
            (response) => {
                // Access the 'data' property of the response and use 'length' to get the number of slots
                const number = response.data.length;
                setAnmeldungen(number);
            }).catch((e) => {
                console.log(e);
            })
        directus.items('workshops').readByQuery({ limit: 1000, filter: { hub: { _eq: hub.id } } }).then(
            (response) => {
                // Access the 'data' property of the response and use 'length' to get the number of slots
                const number = response.data.length;
                setWorkshops(number);
            }).catch((e) => {
                console.log(e);
            })
    }, [hub])

    return (
        <div className="hub-preview">
            <div className="hub-preview__name">{JSON.parse(hub.fields).name}</div>
            <div className="grid-container">

                <div className="grid-item">
                    <span className="number">{anmeldungen}</span>
                    <span className="text">{t('Anzahl Anmeldungen')}</span>
                </div>
                <div className="grid-item">
                    <span className="number">{workshops}</span>
                    <span className="text">{t('Gebuchte Workshops')}</span>
                </div>
                <div className="grid-item">
                    <span className="number">{slots}</span>
                    <span className="text">{t('Erfasste Slots')}</span>
                </div>
            </div>
        </div>
    )
}


HubPreview.propTypes = {
    hub: PropTypes.object.isRequired
};