import React from 'react'

export default function ToDoItem(props) {
    const { state, text } = props;
    return (
        <div className="todo_item">
            <label className="form-control">

                <input checked={state} type="checkbox" />
                {text}
            </label>
        </div>)
}
