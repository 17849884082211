import React, { useEffect, useState } from 'react';
import MaskedInput from './MaskedInput';
import PropTypes from 'prop-types';


export default function DateInput(props) {
    const { value, onChange } = props;
    const [formattedValue, setFormattedValue] = useState('');

    useEffect(() => {
        if (value) {
            const parts = value.split('-');
            if (parts.length === 3) {
                const year = parts[0];
                const month = parts[1];
                const day = parts[2];
                const formattedDate = `${day}.${month}.${year}`;
                setFormattedValue(formattedDate);
            } else {
                setFormattedValue(value);
            }
        } else {
            setFormattedValue('');
        }
    }, [value]);

    const handleChange = (e) => {
        e.preventDefault();
        const enteredDate = e.target.value;
        const formattedDate = formatDate(enteredDate); // Convert to "yyyy-mm-dd" format
        onChange(formattedDate);
    };

    const formatDate = (date) => {
        const parts = date.split('.');
        if (parts.length === 3) {
            const day = parts[0];
            const month = parts[1];
            const year = parts[2];
            return `${year}-${month}-${day}`;
        }
        return date;
    };

    return (
        <div className="input-wrapper small">
            <MaskedInput
                mask="00.00.0000"
                placeholder="dd.mm.yyyy"
                type="birthday"
                value={formattedValue}
                onChange={handleChange}
            />
        </div>
    );
}

DateInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};