import Table from '@containers/table/Table';
import { useDirectus } from 'react-directus';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { UserEntries } from '@helpers/UserEntries';

const User = () => {
    const { directus } = useDirectus();
    const location = useLocation();
    const { id } = useParams();
    const [entriesObject, setEntriesObject] = useState(null);

    const getDataOnLoad = async () => {
        const items = await directus.items('directus_users').readByQuery({
            filter: {
                hub: {
                    _eq: id
                }
            }
        });;
        const myObject = new UserEntries(items.data);
        setEntriesObject(myObject);
        myObject.getFields();
    };
    useEffect(() => {
        getDataOnLoad();
    }, []); // eslint-disable-line

    return <div>
        <Table entries={entriesObject} />
        <Link className={'add-button--wrapper'} to="user" state={{ background: location }}>
            <img className={'add-button'} src={process.env.PUBLIC_URL + '/assets/add.svg'} alt="arrow-filter" />
        </Link>
    </div>;
};

export default User;