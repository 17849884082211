import React, { useEffect, useState } from 'react';
import SlotItem from './SlotItem';
import { useDirectus } from 'react-directus';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function AnmeldungItem({ anmeldung }) {
    const { directus } = useDirectus();
    const [slots, setSlots] = useState([]);

    const getSlots = async (slotIds) => {
        const filteredSlotIds = slotIds.filter(item => item.slots_id).map(item => item.slots_id);

        if (filteredSlotIds.length === 0) return;

        try {
            const response = await directus.items('slots').readByQuery({
                filter: { id: { _in: filteredSlotIds } },
                fields: ['*']
            });
            setSlots(response.data);
        } catch (e) {
            console.error('Error fetching slots:', e);
            // Handle error appropriately
        }
    }

    useEffect(() => {
        getSlots(anmeldung.slots);
    }, [anmeldung.slots, directus.items]); // Assuming anmeldung.slots and directus.items are stable

    return (
        <Link to={`/app/anmeldungen/bearbeiten/${anmeldung.id}`}>
            {slots.length > 0 && (
                <div className="kalender__anmeldung-item">
                    <div className="label">
                        {anmeldung.schule}, {anmeldung.ort}
                    </div>
                    <div>
                        {slots.map((slot) => <SlotItem slot={slot} key={slot.id} />)}
                    </div>
                </div>
            )}
        </Link>
    );
}

AnmeldungItem.propTypes = {
    anmeldung: PropTypes.object.isRequired,
};
