import { format, parse } from 'date-fns';
import { de } from 'date-fns/locale';

function formatDate(dateString) {
    let dateObject;

    if (dateString.length === 8) {
        // Assuming format is 'dd.MM.yy'
        dateObject = parse(dateString, 'dd.MM.yy', new Date());
    } else if (dateString.length === 10) {
        // Assuming format is 'yyyy-MM-dd'
        dateObject = parse(dateString, 'yyyy-MM-dd', new Date());
    } else {
        throw new Error('Invalid date format');
    }

    const formattedDate = format(dateObject, 'EE, dd.MM.yyyy', { locale: de });
    return formattedDate;
}

export default formatDate;
