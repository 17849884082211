import PropTypes from 'prop-types'

const Select = (props) => {
    const { value, onChange, options, className } = props;
    const handleChange = (e) => {
        e.preventDefault();
        onChange(e.target.value);
        console.log(e.target.value);
    }

    return <div className={'input-wrapper ' + className}>
        <select value={value} onChange={handleChange} >
            {options.map(option => (
                <option key={option.value} value={option.value} defaultValue={option.selected} hidden={option.hidden}>
                    {option.text}
                </option>
            ))}
        </select></div>;
};

Select.defaultProps = {
    options: [],
}

Select.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array,
    className: PropTypes.string,
}

export default Select;