import { Entries, UserObject } from "./Entries";

class UserEntries extends Entries {
    constructor(rawData) {
        super(rawData); // Call the constructor of the parent class

        this.attributes = UserObject.getAttributes();
    }
}

export { UserEntries };