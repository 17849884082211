import React, { memo } from 'react';
import PropTypes from 'prop-types';
import formatDate from '@helpers/formatDate';
import './slot-item.scss';

const SlotItem = memo(({ slot, reducerState, onChange, removeSlotHandler }) => {
    const { id, date, zeit, kontingent } = slot;
    const isSelected = reducerState?.slots ? reducerState.slots.includes(id) : false;

    const handleRemoveClick = () => {
        if (removeSlotHandler) {
            removeSlotHandler(id);
        }
    };

    return (
        <div key={`${date}-${zeit}`} className="slot-selection-wrapper">
            {onChange && (
                <input
                    type="checkbox"
                    id={id}
                    name={id}
                    value={id}
                    checked={isSelected}
                    onChange={onChange} // Assuming an onChange handler is required
                />
            )}
            <label className="slot-selection" htmlFor={id}>
                {formatDate(date)} - {zeit.charAt(0).toUpperCase() + zeit.slice(1)} - {kontingent} Workshops
                {removeSlotHandler && (
                    <button onClick={handleRemoveClick} className="delete">
                        löschen
                    </button>
                )}
            </label>
        </div>
    );
});

SlotItem.defaultProps = {
    onChange: null,
    removeSlotHandler: null,
};

SlotItem.propTypes = {
    slot: PropTypes.object.isRequired,
    reducerState: PropTypes.object,
    onChange: PropTypes.func,
    removeSlotHandler: PropTypes.func,
};

export default SlotItem;
