import React, { useMemo, memo } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';



const CustomLoader = () => <div>Loading...</div>;

const EmptyComponent = () => <div>No Data Available</div>;

const AnmeldungenTable = memo((props) => {
    const { entries, filter } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const statusCellStyle = (row) => {
        return {
            color: row.status !== 'open' ? '#069C56' : '#FF681E',
            borderRadius: 5,
            border: '2px solid currentColor',
            paddingLeft: '4px',
            paddingRight: '4px',
            lineHeight: 1.5,
            fontWeight: 500,
        };
    };

    const zuteilungCellStyle = (row) => {
        return {
            color: row.zuteilung !== 'nicht zugeteilt' ? '#069C56' : '#FF681E',
            borderRadius: 5,
            border: '2px solid currentColor',
            paddingLeft: '4px',
            paddingRight: '4px',
            lineHeight: 1.5,
            fontWeight: 500,
        };
    };


    const convertStatusToText = (status) => {
        if (status === 'open') {
            return 'offen'
        } else if (status === 'confirmed') {
            return 'bestätigt'
        } else {
            return status
        }
    }

    const columns = useMemo(() => {
        return entries.getTableColumns(filter).map(column => {
            if (column.key === 'status') {
                return {
                    ...column,
                    cell: (row) => <div style={statusCellStyle(row)}>{convertStatusToText(row.status)}</div>,
                };
            }
            if (column.key === 'zuteilung') {
                return {
                    ...column,
                    cell: (row) => <div style={zuteilungCellStyle(row)}>{convertStatusToText(row.zuteilung)}</div>,
                };
            }
            return column;
        });
    }, [entries, filter]);



    const handleRowClick = (row) => {
        navigate(`bearbeiten/${row.id}`);
    };

    return (
        <DataTable
            columns={columns}
            data={entries.getData()}
            highlightOnHover
            keyField="id"
            defaultSortField="id"
            defaultSortDesc
            responsive
            onRowClicked={handleRowClick}
            progressPending={entries.loading}
            progressComponent={<CustomLoader />}
            noDataComponent={<EmptyComponent />}
        />
    );
});

AnmeldungenTable.propTypes = {
    entries: PropTypes.shape({
        getTableColumns: PropTypes.func.isRequired,
        getData: PropTypes.func.isRequired,
        loading: PropTypes.bool,
    }).isRequired,
    filter: PropTypes.string.isRequired,
};

export { AnmeldungenTable };
