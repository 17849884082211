import React from 'react'
import { formatDate, capitalize } from '@helpers';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function SlotItem(props) {
    const { slot } = props;
    const { t } = useTranslation('dashboard');

    return (
        <div className="kalender__workshop-item">
            <div>
                <div className="date">{formatDate(slot.date)}</div>
                <div className="zeit">{capitalize(slot.zeit)}</div>
            </div>
            <div>
                <div className="button">
                    {JSON.parse(slot.availability) && JSON.parse(slot.availability).available
                        ? JSON.parse(slot.availability).available.length !== 1
                            ? JSON.parse(slot.availability).available.length + ' ' + t('Leiterpersonen verfügbar')
                            : JSON.parse(slot.availability).available.length + ' ' + t('Leiterperson verfügbar')
                        : 0 + ' ' + t('Leiterpersonen verfügbar')
                    }
                </div>
                <div className="button not">
                    {JSON.parse(slot.availability) && JSON.parse(slot.availability).unavailable + ' ' + t('Absagen')
                        ? JSON.parse(slot.availability).unavailable.length + ' ' + t('Absagen')
                        : 0 + ' ' + t('Absagen')
                    }

                </div>
            </div>
        </div>
    )

}

SlotItem.propTypes = {
    slot: PropTypes.object.isRequired,
}