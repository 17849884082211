import React from 'react'
import './button.scss';
import PropTypes from "prop-types";

export default function ButtonGroup({ deleteItem }) {
    return (
        <div className="button-wrapper small button-group">
            <input value={'Speichern'} type="submit" />
            <button
                className="button button--delete small"
                onClick={() => deleteItem()}
            >
                Löschen
            </button>
        </div>
    );
}

ButtonGroup.propTypes = {
    deleteItem: PropTypes.func.isRequired,
};