import ContentWrapper from '@containers/content-wrapper/ContentWrapper';
import { useDirectus } from 'react-directus';
import { useEffect, useState } from 'react';

import { BasicTable } from '@containers/table/BasicTable';

import { getDataOnLoad } from '@helpers/general-helpers';
import { RegionenEntries } from '../helpers';

const collectionName = 'regionen';
const fields = '*, hub.*';

const Regionen = () => {
    const { directus } = useDirectus();
    const [collection, setCollection] = useState(null);

    useEffect(() => {

        getDataOnLoad(collectionName, fields, directus)
            .then((items) => {
                const regionenItems = new RegionenEntries(items.data);
                setCollection(regionenItems);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return <ContentWrapper title={'Regionen'} >
        {collection ?
            <BasicTable entries={collection} />
            : null}
    </ContentWrapper>;
};

export default Regionen;