import { ContentWrapper } from '@containers';
import { useDirectus } from 'react-directus';
import { useEffect, useState } from 'react';
import 'react-data-grid/lib/styles.css';
import { SlotEntries } from '../helpers/SlotEntries';
import { SlotsTable } from '@containers/table/SlotsTable';
import { useTranslation } from 'react-i18next';

import { useError } from '../../../context/ErrorContext';


const SlotsOverviewPage = () => {
    const { directus } = useDirectus();
    const [entriesObject, setEntriesObject] = useState(null);
    const { t } = useTranslation();
    const { setErrorMessage } = useError();

    const handleDelete = (row) => {
        // Delete the slot with the specified ID
        directus.items('slots')
            .deleteOne(row.id)
            .then((response) => {
                // Handle successful deletion if needed
                getDataOnLoad();
            })
            .catch((e) => {
                // Handle error if needed
            });
    };

    const [filter, setFilter] = useState(['date', 'zeit', 'status', 'kontingent']);

    useEffect(() => {
        directus.items('hubs').readByQuery().then(
            (response) => {
                if (response.data[0].enableRegionen === 'true') {
                    const tmpFilter = filter;
                    tmpFilter.push('region');
                    setFilter(tmpFilter);
                }
            }
        ).catch((e) => {
            setErrorMessage(e.message);
        })
    }, [])


    const getDataOnLoad = async () => {

        const items = await directus.items('slots').readByQuery(
            {
                fields: '*, region.*', limit: 1000,
                filter: { status: { _neq: 'booked' } }
            }
        );

        const myObject = new SlotEntries(items.data);
        setEntriesObject(myObject);
        myObject.getFields();

    };
    useEffect(() => {
        getDataOnLoad();
    }, []);




    return <ContentWrapper title={t('Slots')} >
        {entriesObject ?
            <SlotsTable entries={entriesObject} onDelete={handleDelete} filter={filter} // Pass the delete handler to SlotsTable
            />
            : null}
    </ContentWrapper>;
};

export default SlotsOverviewPage;