import React from 'react';
import PropTypes from 'prop-types';
import './historie.scss';

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('de-DE', { month: 'long' });
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return `${day}. ${month} ${year} ${hour}:${minute}`;
}


export default function HistoryItems({ data }) {

    return (
        <div>
            <h2>Historie</h2>
            <div className="history-wrapper">
                {data && (
                    <div className="history-item">
                        <span className="history-item__date">
                            {formatDate(data.date_created)}
                        </span>
                        <span className="history-item__message">Anmeldung erfolgreich eingegangen</span>
                    </div>
                )}
                {data.history ? (
                    JSON.parse(data.history).map((historyItem) => (
                        <div key={historyItem.id} className={`history-item`}>
                            <span className="history-item__date">
                                {formatDate(historyItem.date)}
                            </span>
                            <span className="history-item__message">{historyItem.message}</span>
                        </div>
                    ))
                ) : null}
            </div>
        </div>
    );
}

HistoryItems.propTypes = {
    data: PropTypes.object.isRequired
};