import React, { useEffect, useState } from 'react'
import { Modal } from '@containers/modal/modal'
import UiBlock from '@containers/ui-block/UiBlock';
import Slots from './Slots';
import SlotSelection from './SlotSelection';
import { useDirectus } from 'react-directus';

import { createUpdateObject, sortSlots } from './helpers';
import { useError } from '@context/ErrorContext';
import PropTypes from 'prop-types';

import './edit-slots-modal.scss';
import Textarea from '../../../../components/input-fields/textfield/Textarea';
import { GeneralButtonGroup } from '../../../../components';

import { useTranslation } from 'react-i18next';


export default function EditSlotsModal(props) {
    const { directus } = useDirectus();
    const { setErrorMessage, setSuccessMessage } = useError();

    const { id, reloadData } = props;

    const [availableSlots, setAvailableSlots] = useState([]);
    const [slots, setSlots] = useState([]);
    const [initialData, setInitialData] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [historieValue, setHistorieValue] = useState('');
    const [historyData, setHistoryData] = useState([]);

    const { t } = useTranslation('anmeldung');


    const resetState = () => {
        setSlots([]);
        setInitialData([]);
        setHistorieValue('');
        setHistoryData([]);
    }


    useEffect(() => {

        if (id !== undefined) {
            directus.items('anmeldungen').readOne(id, { fields: ['slots.*', 'history'] }).then(
                (response) => {
                    const fetchedData = response;
                    fetchedData.slot_ids = fetchedData.slots.map((slot) => slot.slots_id);

                    setSlots(fetchedData.slot_ids);
                    setInitialData(fetchedData.slots)
                    const tmpHistoryData = fetchedData?.history !== undefined ? JSON.parse(fetchedData?.history) : [];

                    setHistoryData(tmpHistoryData);

                }).catch((e) => {
                    setErrorMessage(t('Fehler beim Laden der Daten'));
                })
        }

    }, [modalIsOpen])

    useEffect(() => {
        let filterCondition = {
            status: {
                _eq: 'open',
            },
        };

        directus.items('slots').readByQuery({
            filter: filterCondition,
            sort: 'date,-zeit',
            limit: 1000,
        }).then(
            (response) => {
                const tmpSlots = sortSlots(response.data)

                setAvailableSlots(tmpSlots);

            }).catch((e) => {
                console.log(e);
            })


    }, [modalIsOpen])

    const handleSlotSelection = (e, slotId) => {
        const isChecked = e.target.checked;

        if (isChecked) {

            const newSlots = [...slots];
            if (!newSlots.includes(slotId)) {
                newSlots.push(slotId);
            }
            setSlots(newSlots);

        } else {
            const newSlots = slots.filter((slot) => slot !== slotId);
            console.log(newSlots)
            setSlots(newSlots);
        }
    };

    const removeSlot = (slotId) => {
        const newSlots = slots.filter((slot) => slot !== slotId);
        setSlots(newSlots);
    }

    const handleSave = () => {

        if (historieValue === '') {
            setErrorMessage(t('Bitte einen Kommentar für die Historie eingeben'));
            return;
        }

        const itemsToBeRemoved = initialData.filter((slot) => !slots.includes(slot.slots_id)).map((slot) => slot.id);
        const slotsToBeRemoved = initialData.filter((slot) => !slots.includes(slot.slots_id)).map((slot) => slot.slots_id);
        const itemsToBeCreated = slots.filter((slot) => !initialData.map((slot) => slot.slots_id).includes(slot));
        const slotsToBeCreated = slots.filter((slot) => !initialData.map((slot) => slot.slots_id).includes(slot));

        directus.items('anmeldungen').updateOne(id, {
            slots: createUpdateObject(itemsToBeCreated, itemsToBeRemoved, id),

        }).then((response) => {

            setSuccessMessage(t('Slots erfolgreich aktualisiert'));
            setModalIsOpen(false);
            reloadData();
        }).catch((e) => {
            setErrorMessage(t('Fehler beim Aktualisieren der Slots'));
        })

        const newHistory = historyData !== null ? historyData : [];
        newHistory.push({ date: new Date(), message: `${t('Termine bearbeitet')}: ${historieValue}` });


        directus.items('anmeldungen').updateOne(id, {
            history: JSON.stringify(newHistory)
        }).then((response) => {

        }).catch((e) => {
        })

        if (slotsToBeRemoved.length > 0) {
            directus.items('slots').updateMany(slotsToBeRemoved, {
                status: 'open',
                anmeldungen_id: null
            }).then((response) => {
                console.log(slotsToBeRemoved)
                console.log('removed')
                reloadData();

            }).catch((e) => {
                console.log(slotsToBeRemoved)
                console.log('not removed')
            })
        }

        if (slotsToBeCreated.length > 0) {
            directus.items('slots').updateMany(slotsToBeCreated, {
                status: 'booked',
                anmeldungen_id: id
            }).then((response) => {
                console.log(slotsToBeCreated)
                console.log('created')
            }).catch((e) => {
                console.log(slotsToBeCreated)
                console.log('not created')
            })
        }
        resetState();
    }

    return (
        <div className="edit-slots-modal general-input">
            <GeneralButtonGroup buttons={[{ text: t('Termine bearbeiten'), handleOnClick: () => setModalIsOpen(true), classNames: 'button--inline' }]} />

            {modalIsOpen ?
                <Modal title={t('Slots bearbeiten')} inline={true} onClose={() => setModalIsOpen(false)}>
                    <UiBlock title={t('Verknüpfte Slots')} >
                        <Slots slotIds={slots} removeSlotHandler={removeSlot} />
                        <SlotSelection availableSlots={availableSlots} handleSlotSelection={handleSlotSelection} />
                        <Textarea
                            value={historieValue || ''}
                            onChange={(value) =>
                                setHistorieValue(value)
                            }
                            placeholder={t('kommentar_history')}
                        />
                    </UiBlock>
                    <GeneralButtonGroup buttons={[{ text: t('Speichern'), handleOnClick: () => handleSave(), classNames: 'button--inline' }, { text: t('Abbrechen'), handleOnClick: () => setModalIsOpen(false), classNames: 'button--inline' }]} />
                </Modal> : null}
        </div>

    )
}

EditSlotsModal.propTypes = {
    id: PropTypes.number.isRequired
}