import React, { useReducer, useEffect } from 'react';
import '@containers/add-hub/change-password.scss';
import { useDirectus } from 'react-directus';
import { useParams, useNavigate } from 'react-router-dom';
import { HubObject } from '../helpers/HubEntries';
import { useError } from '../../../context/ErrorContext';

import { Textfield } from '@components/input-fields';
import ButtonGroup from '../../../components/input-fields/button/ButtonGroup';
import PropTypes from 'prop-types';
const initialState = HubObject.getInitialState();
initialState.enableRegionen = '';

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state,
                [action.key]: action.payload,
            };
        case 'FETCH':
            return action.payload;

        default:
            return state;
    }
}

function AddHub(props) {
    const { setErrorMessage, setSuccessMessage } = useError();

    const deleteItem = async () => {
        await directus.items('hubs').deleteOne(id).then(
            (response) => {
                navigate('/app/hubs');
            }
        ).catch((e) => {
            setErrorMessage(e.message);
        }
        )
    }


    const { isEditPage } = props;
    const navigate = useNavigate();

    let { id } = useParams();
    const { directus } = useDirectus();

    const [reducerState, dispatch] = useReducer(reducer, initialState);

    const handleSubmit = (e) => {
        e.preventDefault();

        const { enableRegionen, ...rest } = reducerState;

        if (isEditPage) {
            directus.items('hubs')
                .updateOne(id, { fields: JSON.stringify(rest), enableRegionen: String(enableRegionen) })
                .then((response) => {
                    setSuccessMessage('Hub wurde erfolgreich aktualisiert.');
                })
                .catch((e) => {

                    setErrorMessage(e.message);
                });
        } else {
            directus.items('hubs')
                .createOne({ fields: JSON.stringify(rest), enableRegionen: String(enableRegionen) })
                .then((response) => {
                    setSuccessMessage('Hub wurde erfolgreich erstellt.');
                    navigate('/app/hubs/bearbeiten/' + response.id);
                })
                .catch((e) => {
                    setErrorMessage(e.message);
                });
        }
    };

    useEffect(() => {
        if (id !== undefined && isEditPage) {
            directus.items('hubs')
                .readOne(id)
                .then((response) => {
                    const fetchedData = JSON.parse(response.fields);
                    const mergedData = { ...initialState, ...fetchedData };
                    mergedData.enableRegionen = response.enableRegionen === 'true' ? true : false;
                    dispatch({
                        type: 'FETCH',
                        payload: mergedData,
                    });
                })
                .catch((e) => {

                    setErrorMessage(e.message);
                });
        }
    }, [directus, id, isEditPage]);

    const renderTextFields = () => {
        return HubObject.getFields().map((obj) => (
            <Textfield
                className="small"
                value={reducerState[obj.key]}
                onChange={(value) =>
                    dispatch({
                        type: 'UPDATE',
                        payload: value,
                        key: obj.key,
                    })
                }
                placeholder={obj.name}
                key={obj.key}
            />
        ));
    };

    return (
        <div className={'change-password'}>
            <form onSubmit={handleSubmit}>
                {renderTextFields()}

                <input
                    type="checkbox"
                    id={`slot-selection-checkbox`}
                    name={'slotSelection'}
                    value={'slotSelection'}
                    checked={reducerState.enableRegionen}
                    onChange={() => dispatch({
                        type: 'UPDATE',
                        payload: !reducerState.enableRegionen,
                        key: 'enableRegionen',
                    })
                    }
                />
                <label htmlFor={`slot-selection-checkbox`}>Hiermit kannst du diesem Hub die Möglichkeit freischalten, zusätzliche Regionen zu verwenden.</label>
                <ButtonGroup deleteItem={deleteItem} />
            </form>
        </div>
    );
}

AddHub.defaultProps = {
    isEditPage: false,
};

AddHub.propTypes = {
    isEditPage: PropTypes.bool,
};

export default AddHub;