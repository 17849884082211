import React from 'react';
import './ui-block.scss';
import PropTypes from 'prop-types';

export default function UiBlock(props) {
    const { title, text } = props;

    return (
        <div className={'ui-block'}>
            <h2>{title}</h2>
            <p>{text}</p>
            {props.children}
        </div>
    )
}

UiBlock.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};