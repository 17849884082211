import { useEffect, useState } from 'react';
import './dashboard.scss';
import { useDirectus } from 'react-directus';
import Kalender from './components/Kalender';
import HubPreview from './components/HubPreview';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {

    const { directus } = useDirectus();
    const [slots, setSlots] = useState(null);
    const [anmeldungen, setAnmeldungen] = useState(null);
    const [workshops, setWorkshops] = useState(null);
    const [hubs, setHubs] = useState(null);
    const { t } = useTranslation('dashboard');


    useEffect(() => {

        directus.items('hubs').readByQuery({ limit: 1000 }).then(
            (response) => {
                setHubs(response.data);
            }).catch((e) => {
                console.log(e);
            })
        directus.items('slots').readByQuery({ limit: 10000 }).then(
            (response) => {
                // Access the 'data' property of the response and use 'length' to get the number of slots
                const numberOfSlots = response.data.length;
                setSlots(numberOfSlots);
            }).catch((e) => {
                console.log(e);
            })

        directus.items('anmeldungen').readByQuery({ limit: 10000 }).then(
            (response) => {
                // Access the 'data' property of the response and use 'length' to get the number of slots
                const numberOfSlots = response.data.length;
                setAnmeldungen(numberOfSlots);
            }).catch((e) => {
                console.log(e);
            })
        directus.items('workshops').readByQuery({ limit: 1500 }).then(
            (response) => {
                // Access the 'data' property of the response and use 'length' to get the number of slots
                const numberOfSlots = response.data.length;
                setWorkshops(numberOfSlots);
            }).catch((e) => {
                console.log(e);
            })
    }, [])


    return <div className="dashboard-page">
        <div className="hub-preview">
            <div className="hub-preview__name">{t('Total')}</div>
            <div className="grid-container">

                <div className="grid-item">
                    <span className="number">{anmeldungen}</span>
                    <span className="text">{t('Anzahl Anmeldungen')}</span>
                </div>
                <div className="grid-item">
                    <span className="number">{workshops}</span>
                    <span className="text">{t('Gebuchte Workshops')}</span>
                </div>
                <div className="grid-item">
                    <span className="number">{slots}</span>
                    <span className="text">{t('Erfasste Slots')}</span>
                </div>
            </div>
        </div>
        {hubs?.length > 0 ? hubs.map((hub) => <HubPreview hub={hub} />) : null}

        <div>

            <Kalender />
        </div>
    </div>;
};

export default Dashboard;