import './content-wrapper.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ContentWrapper = ({ title, addButton, exportButton, children }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    const renderAddButton = () => (
        <Link className='add-button--wrapper' to='neu' state={{ background: location }}>
            <img className='add-button' src='/assets/add.svg' alt='Add New' />
        </Link>
    );

    const renderBackButton = () => (
        <div className='add-button--wrapper' onClick={goBack}>
            <img className='add-button' src='/assets/back.svg' alt='Go Back' />
        </div>
    );

    return (
        <div className='content-wrapper'>
            <div className='content-wrapper--header'>
                <span>{title}</span>
                <div className='content-wrapper--buttons'>
                    {exportButton}
                    {addButton ? renderAddButton() : renderBackButton()}
                </div>
            </div>
            <div className='content-wrapper--main'>{children}</div>
        </div>
    );
};

ContentWrapper.defaultProps = {
    addButton: true,
    exportButton: false,
};

ContentWrapper.propTypes = {
    title: PropTypes.string.isRequired,
    addButton: PropTypes.bool,
    exportButton: PropTypes.node,
    children: PropTypes.node.isRequired,
};

export default ContentWrapper;
