import React, { useEffect, useRef } from 'react';
import IMask from 'imask';
import PropTypes from 'prop-types';

export default function MaskedInput(props) {
    const { mask, placeholder, value, onChange } = props;
    const inputRef = useRef(null);

    useEffect(() => {
        const maskOptions = {
            mask,
            // Add any other options you need, such as pattern, format, etc.
        };

        const maskInstance = IMask(inputRef.current, maskOptions);

        return () => {
            maskInstance.destroy();
        };
    }, [mask]);

    return <div className={'input-wrapper '}>
        <input ref={inputRef} value={value} onChange={onChange} type="text" placeholder={placeholder} />
        <label>{placeholder}</label>
    </div>;
}

MaskedInput.propTypes = {
    mask: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};