import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function BasicTable(props) {
    const { entries, filter } = props;
    const navigate = useNavigate();

    const handleRowClick = (row) => {
        // Redirect to the edit page with the row ID
        navigate(`bearbeiten/${row.id}`);

    };

    return (
        <DataTable
            columns={entries.getTableColumns(filter)}
            data={entries.getData()}
            highlightOnHover
            onRowClicked={handleRowClick}
            keyField="id"
            defaultSortField="id"
            defaultSortDesc
            responsive={true}
        />
    );
};

BasicTable.propTypes = {
    entries: PropTypes.object.isRequired,
    filter: PropTypes.string
};

export { BasicTable };