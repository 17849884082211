import React, { useState, useEffect } from 'react'
import UiBlock from '@containers/ui-block/UiBlock'
import { useDirectus } from 'react-directus';

import MessageBox from '@components/message-box/MessageBox';
import { Textfield, Button } from '@components';

import { useNavigate } from 'react-router-dom';

export default function DeactivateTFA() {
    const { directus } = useDirectus();

    const [msg, setMsg] = useState(null);
    const [err, setErr] = useState(null);


    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');

    const [twoFA, set2FA] = useState(false);

    const navigate = useNavigate();

    const getUser = async () => {
        const me = await directus.users.me.read();
        console.log(me.tfa_secret)
        if (me.tfa_secret != null) {
            set2FA(true);
        }
    }

    useEffect(() => {
        getUser();
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log('disable 2FA');
        directus.users.me.tfa.disable(otp).then(
            (response) => {
                setMsg('2FA erfolgreich deaktiviert.');
                navigate('/app/einstellungen');

            }).catch((e) => {
                setMsg('Fehler beim Deaktivieren der 2FA: ' + e.message);
                setErr(true);
            })

    }

    return (
        <UiBlock text={'Hier kannst du 2FA deaktivieren'}
            title={'2FA deaktivieren'}>
            <div className={'change-password'}>
                <form onSubmit={e => { handleSubmit(e) }}>

                    <Textfield className="small" value={password} onChange={setPassword} placeholder="Passwort" type="password" />
                    <Textfield className="small" value={otp} onChange={setOtp} placeholder="One-Time-Password" />

                    <Button
                        type='submit'
                        value={twoFA ? 'deaktivieren' : 'aktivieren'}
                        className='small'
                    />
                </form>
            </div>
            <MessageBox
                msg={msg}
                clearMsg={() => setMsg(null)}
                error={err}
                clearError={() => setErr(null)}
            />
        </UiBlock>

    )
}