import { useDirectus } from 'react-directus';
import { useEffect, useState } from 'react';

import { BasicTable } from '@containers/table/BasicTable';
import ContentWrapper from '@containers/content-wrapper/ContentWrapper';

import { getDataOnLoad } from '@helpers/general-helpers';
import { LeiterpersonenEntries } from '../helpers';
import { useTranslation } from 'react-i18next';

const collectionName = 'leiterpersonen';
const fields = null;

const Leiterpersonen = () => {
    const { directus } = useDirectus();
    const [collection, setCollection] = useState(null);
    const { t } = useTranslation('leiterpersonen');

    useEffect(() => {

        getDataOnLoad(collectionName, fields, directus)
            .then((items) => {
                const leiterpersonenItems = new LeiterpersonenEntries(items.data);
                setCollection(leiterpersonenItems);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return <ContentWrapper title={t('Leiterpersonen')} >
        {collection ?
            <BasicTable entries={collection} filter={['vorname', 'nachname', 'email', 'erste_ausbildung']} />
            : null}
    </ContentWrapper>;
};

export default Leiterpersonen;