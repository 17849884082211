import PropTypes from 'prop-types';
import './button.scss';

const Button = (props) => {
    const { value, className } = props;

    return <div className={'button-wrapper ' + className}>
        <input value={value} type='submit' />
    </div>;
};

Button.propTypes = {
    value: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default Button;