import React, { useState, useEffect } from 'react';
import './message-box.scss';
import PropTypes from 'prop-types'
import { act } from 'react-dom/test-utils';

function MessageBox({ msg, clearMsg, error, clearError, alert }) {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (msg) {
            setVisible(true);

            if (alert) {
                const timeout = setTimeout(() => {
                    setVisible(false);
                    clearMsg();
                    clearError();
                }, 5000);

                return () => clearTimeout(timeout);
            }
        }
    }, [msg]);

    return (
        visible && (
            <div className={`message-box-wrapper ${alert ? '' : 'inline'}`} style={{ display: visible ? 'flex' : 'none' }}>
                <div className={`message-box ${visible ? 'active' : ''} ${error ? 'error' : ''}`}>
                    {msg}
                </div>
            </div>

        )

    );
}

MessageBox.defaultProps = {
    alert: true,
};

MessageBox.propTypes = {
    msg: PropTypes.string.isRequired,
    clearMsg: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    clearError: PropTypes.func.isRequired,
    alert: PropTypes.bool,
}

export default MessageBox;
