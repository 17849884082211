import React, { useState, useReducer, useEffect } from 'react';
import { useDirectus } from 'react-directus';
import { useParams, useNavigate } from 'react-router-dom';

import { Textfield } from '@components';
import ButtonGroup from '../../../components/input-fields/button/ButtonGroup';

import MessageBox from '@components/message-box/MessageBox';

const initialState = {
    first_name: '',
    last_name: '',
    email: '',
};

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state,
                [action.key]: action.payload,
            }
        case 'FETCH':
            return action.payload

        default:
            return state
    }
}


function AddUser(props) {

    const navigate = useNavigate();
    const { isEditPage, hubId } = props;

    let { userId } = useParams();
    const { directus } = useDirectus();

    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const [msg, setMsg] = useState(null);
    const [err, setErr] = useState(null);

    const deleteItem = async () => {
        await directus.users.deleteOne(userId).then(
            (response) => {
                navigate('/app/user');
            }
        ).catch((e) => {
            setErr(e.message);
            setMsg(e.message);
        }
        )
    }

    async function getBasicRoleId() {
        try {
            const query = {
                filter: {
                    name: 'Basic',
                },
            };

            const roles = await directus.roles.readByQuery(query);
            return roles.data[0].id;
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    }

    getBasicRoleId();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isEditPage) {
            const { first_name, last_name } = reducerState;
            directus.items('directus_users').updateOne(userId, { first_name, last_name }).then(
                (response) => {
                    setMsg('User wurde erfolgreich aktualisiert.');
                }).catch((e) => {
                    setErr(true);
                    setMsg(e.message);
                })
        } else {

            const API_ENDPOINT = process.env.REACT_APP_FRONTEND_URL;


            const basicRoleId = await getBasicRoleId();


            directus.users.invites.send(reducerState.email, basicRoleId, API_ENDPOINT + '/invitation').then((res) => {
                setMsg('Der Benutzer wurde erfolgreich eingeladen.')

                directus.items('directus_users').readByQuery({
                    filter: {
                        email: { _eq: reducerState.email }
                    }
                }).then(response => {
                    const userId = response.data[0].id; // Replace 0 with the index of the user you want to update
                    const updatedUserData = {
                        first_name: reducerState.first_name,
                        last_name: reducerState.last_name,
                        hub: hubId
                    };
                    directus.items('directus_users').updateOne(userId, updatedUserData).then(response => {
                    }).catch(error => {
                        console.error(error);
                    });
                }).catch(error => {
                    console.error(error);
                });
            })




        }

    }

    useEffect(() => {
        if (userId !== undefined && isEditPage) {
            directus.items('directus_users').readOne(userId).then(
                (response) => {
                    const fetchedData = response;
                    const mergedData = { ...initialState, ...fetchedData }

                    dispatch({
                        type: 'FETCH',
                        payload: mergedData
                    })

                }).catch((e) => {
                    setErr(e.message);

                })
        }
    }, [directus, isEditPage, userId])

    return (
        <div className={'change-password'}>
            <form onSubmit={e => { handleSubmit(e) }}>
                <Textfield
                    className="small"
                    value={reducerState.first_name}
                    onChange={(value) =>
                        dispatch({
                            type: 'UPDATE',
                            payload: value,
                            key: 'first_name',
                        })
                    }
                    placeholder="Vorname"
                />
                <Textfield
                    className="small"
                    value={reducerState.last_name}
                    onChange={(value) =>
                        dispatch({
                            type: 'UPDATE',
                            payload: value,
                            key: 'last_name',
                        })
                    }
                    placeholder="Nachname"
                />
                <Textfield
                    className="small"
                    value={reducerState.email}
                    onChange={(value) =>
                        dispatch({
                            type: 'UPDATE',
                            payload: value,
                            key: 'email',
                        })
                    }
                    placeholder="Email"
                />
                <ButtonGroup deleteItem={deleteItem} />

            </form>
            <MessageBox
                msg={msg}
                clearMsg={() => setMsg(null)}
                error={err}
                clearError={() => setErr(null)}
            />
        </div>
    )
}

AddUser.defaultProps = {
    isEditPage: false,
};

export default AddUser;
