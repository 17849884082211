import React from 'react';
import MaskedInput from './MaskedInput';
import PropTypes from 'prop-types';


export default function TimeInput(props) {
    const { type, value, onChange } = props;

    const handleChange = (e) => {
        e.preventDefault();
        onChange(e.target.value);
    }
    return (

        <MaskedInput
            mask="00:00"
            placeholder="hh:mm"
            type="time"
            value={value}
            onChange={handleChange}
        />
    );
}

TimeInput.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};