import React, { useState, useEffect } from 'react';
import { useDirectus } from 'react-directus';
import { useParams, useNavigate } from 'react-router-dom';
import UiBlock from '@containers/ui-block/UiBlock';

import ButtonGroup from '../../../components/input-fields/button/ButtonGroup';
import { useError } from '../../../context/ErrorContext';
import { useTranslation } from 'react-i18next';

function AddSlots(props) {
    const [collectionRegionenItems, setCollectionRegionenItems] = useState([]);
    const { t } = useTranslation();

    let { id } = useParams();
    const navigate = useNavigate();
    const { directus } = useDirectus();

    const [selectedRegion, setSelectedRegion] = useState(null);
    const [kontingent, setKontingent] = useState(null);
    const { setErrorMessage, setSuccessMessage } = useError();

    const [enableRegionen, setEnableRegionen] = useState(false);

    const getCollectionRegionenItems = async () => {
        const hubItems = await directus.items('regionen').readByQuery();
        const convertedData = hubItems.data.map(item => ({
            id: item.id,
            name: item.name
        }));
        setCollectionRegionenItems(convertedData);
    };

    useEffect(() => {
        getCollectionRegionenItems();
    }, []);

    const deleteItem = async () => {
        await directus.items('slots').deleteOne(id).then(
            (response) => {
                navigate('/app/slots');
            }
        ).catch((e) => {
            setErrorMessage(e.message);
        }
        )
    }

    useEffect(() => {
        directus.items('hubs').readByQuery().then(
            (response) => {
                setEnableRegionen(response.data[0].enableRegionen === 'true' ? true : false)
            }
        ).catch((e) => {
            setErrorMessage(e.message);
        })
    }, [])


    useEffect(() => {


        directus.items('slots').readOne(id).then(
            (response) => {
                const fetchedData = response;
                console.log(fetchedData)
                setSelectedRegion(fetchedData.region);
                setKontingent(fetchedData.kontingent)
            }).catch((e) => {
                setErrorMessage(e.message);
            })

    }, [])



    const handleSubmit = (e) => {
        e.preventDefault();

        directus.items('slots').updateOne(id, { region: selectedRegion, kontingent: kontingent }).then(
            (response) => {
                setSuccessMessage(t('Slot wurde erfolgreich aktualisiert.'));
            }).catch((e) => {
                setErrorMessage(e.message);
            })
    }

    return (
        <div className={'base-container'}>

            <UiBlock text={t('Hier kannst du eine Slots bearbeiten.')} title={t('Slots bearbeiten')}>
                <div className={'base-container'}>
                    <form onSubmit={e => { handleSubmit(e) }}>
                        {enableRegionen ?
                            <select
                                id="selectItem"
                                value={selectedRegion}
                                onChange={(e) => setSelectedRegion(e.target.value)}
                            >
                                <option value="">Region auswählen</option>
                                {collectionRegionenItems.map(item => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select> : null}
                        <div className="input-wrapper small">
                            <input
                                type="number"
                                min="2"
                                max="4"
                                step="2"
                                value={kontingent}
                                onChange={(e) => setKontingent(e.target.value)}
                                placeholder={t("Kontingent")}
                                defaultValue={2}
                            />
                        </div>

                        <ButtonGroup deleteItem={deleteItem} />
                    </form>
                </div>
            </UiBlock>
        </div>

    )
}

AddSlots.defaultProps = {
    isEditPage: false,
};

export default AddSlots;