import React from 'react';

import UiBlock from '@containers/ui-block/UiBlock';

import { useTranslation } from 'react-i18next';

import { Link, useLocation } from 'react-router-dom';

function AddSlots(props) {
    const location = useLocation();
    const { t } = useTranslation();

    const { isEditPage } = props;

    const text = <div>Hier kannst du mehrere Slots auf einmal importieren. Und hier kannst du eine <a href="/import.csv" style={{ zIndex: 1000 }} download>Beispieldatei herunterladen</a>. Bearbeite diese Datei, speichere Sie wieder als CSV und lade sie hoch.
    </div>

    return (
        <div className={'base-container'}>

            <UiBlock text={isEditPage ? 'Hier kannst du eine Slots bearbeiten.' : 'Hier kannst du neue Slots erfassen. Es gibt zwei optionen, entweder kannst du im Kalender die Slots manuell erfassen oder mit einer CSV Datei importieren.'} title={isEditPage ? 'Slots bearbeiten' : 'Neue Slots erfassen'}>
                <div className="two-squares-wrapper">
                    <Link to={"erfassen"} state={{ background: location }} className="square">
                        <div className="square__title">{t('Im Kalender erfassen')}</div>
                        <div className="square__text">{t('Hier kannst du einem Kalender Slots auswählen und so die möglichen Termine manuell erfassen.')}</div>
                    </Link>
                    <Link to={"importieren"} state={{ background: location }} className="square">
                        <div className="square__title">{t('Aus Datei importieren')}</div>
                        <div className="square__text">{'Hier kannst du Slots mit einer CSV Datei importieren. Dazu kannst du z.B.im Excel mögliche Termine erfassen und anschliessend als CSV Datei speichern und hier importieren.'}</div>
                    </Link>
                </div>
            </UiBlock>
        </div>

    )
}

AddSlots.defaultProps = {
    isEditPage: false,
};

export default AddSlots;