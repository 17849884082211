import React, { useState, useEffect } from 'react';
import '@containers/add-hub/change-password.scss';
import { useDirectus } from 'react-directus';
import { useError } from '../../../context/ErrorContext';

import DateInput from '../../../components/input-fields/date/DateInput';
import { Button } from '../../../components';
import { useTranslation } from 'react-i18next';

function HubSettings(props) {
    const { setErrorMessage, setSuccessMessage } = useError();

    const [id, setId] = useState(null);
    const { t } = useTranslation('einstellungen');

    const { directus } = useDirectus();
    const [slotSelectionState, setSlotSelectionState] = useState(false);
    const [enableWednesdayState, setEnableWednesdayState] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const getId = async () => {
        const newMe = await directus.users.me.read();
        setId(newMe.hub);
    }

    useEffect(() => {
        getId();

        if (id === null) return;
        directus.items('hubs')
            .readOne(id)
            .then((response) => {

                console.log(response.slotSelection);
                setSlotSelectionState(response.slotSelection === 'true' ? true : false);
                setEnableWednesdayState(response.enableWednesday === 'true' ? true : false);
                setStartDate(response.startDate);
                setEndDate(response.endDate);
            })
            .catch((e) => {
                setErrorMessage(e.message);
            });

    }, [id]);

    const handleChange = (e) => {
        directus.items('hubs')
            .updateOne(id, { slotSelection: !slotSelectionState })
            .then((response) => {
                setSuccessMessage(t('hub_einstellungen_sucess'));

            })
            .catch((e) => {
                setErrorMessage(e.message);
            });
        setSlotSelectionState(!slotSelectionState);

    }

    const handleWednesdayChange = (e) => {
        directus.items('hubs')
            .updateOne(id, { enableWednesday: !enableWednesdayState })
            .then((response) => {
                setSuccessMessage(t('hub_einstellungen_sucess'));

            })
            .catch((e) => {
                setErrorMessage(e.message);
            });
        setEnableWednesdayState(!enableWednesdayState);

    }


    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(startDate, endDate)


        directus.items('hubs')
            .updateOne(id, { startDate: startDate, endDate: endDate })
            .then((response) => {
                setSuccessMessage(t('hub_einstellungen_sucess'));

            })
            .catch((e) => {
                setErrorMessage(e.message);
            });


    }


    return (
        <div className={'changfe-password'}>
            <div className="left-section">
                <input
                    type="checkbox"
                    id={`slot-selection-checkbox`}
                    name={'slotSelection'}
                    value={'slotSelection'}
                    checked={slotSelectionState}
                    onChange={handleChange}
                />
                <label htmlFor={`slot-selection-checkbox`}>{t('hub_einstellungen_text_slots')}</label>
            </div>
            <div className="left-section">
                <input
                    type="checkbox"
                    id={`wednesday-enabled-checkbox`}
                    name={'slotSelection'}
                    value={'slotSelection'}
                    checked={enableWednesdayState}
                    onChange={handleWednesdayChange}
                />
                <label htmlFor={`wednesday-enabled-checkbox`}>{t('hub_einstellungen_text_mittwoch')}</label>
            </div>
            {!slotSelectionState &&





                <form onSubmit={e => { handleSubmit(e) }}>
                    <div className="left-section ui-block" style={{ marginTop: 10 }}>
                        <h2>{t('Startdatum')}</h2>

                        <p>{t('startdatum_text')}</p>
                        <DateInput
                            key={"startDate"}
                            value={startDate}
                            onChange={(value) =>
                                setStartDate(value)
                            } />
                    </div>
                    <div className="left-section ui-block">
                        <h2>{t('Enddatum')}</h2>
                        <p>{t('enddatum_text')}</p>
                        <DateInput
                            key={"endDate"}
                            value={endDate}
                            onChange={(value) =>
                                setEndDate(value)
                            } />
                    </div>
                    <Button
                        type='submit'
                        value={t('Speichern')}
                        className='small'
                    />

                </form>}

        </div>
    );
}

HubSettings.defaultProps = {
    isEditPage: false,
};

export default HubSettings;