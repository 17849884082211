import React, { useState, useEffect } from 'react';
import './checkboxes.scss';
import PropTypes from 'prop-types';

export default function Checkboxes(props) {

    const { items, options, dispatch, group } = props;
    const [state, setState] = useState(new Array(options.length).fill(false));

    useEffect(() => {
        const searchString = items;
        const searchArray = searchString.split(',');
        const result = options.map(item => searchArray.includes(item.name));
        setState(result);

    }, [items])

    const handleOnChange = (index) => {
        const updatedState = [...state]; // Create a copy of the state array
        updatedState[index] = !state[index]; // Toggle the value at the specified index

        const filteredData = options.filter((item, index) => updatedState[index]);
        const textArray = filteredData.map(item => item.name);

        dispatch(textArray.join(','));
    }
    return (
        <ul className="ausbildung-list">
            {options.map((item, index) => {
                return (
                    <li key={item.name}>
                        <div className="ausbildung-list-item">
                            <div className="left-section">
                                <input
                                    type="checkbox"
                                    id={`custom-checkbox-${group}-${index}`}
                                    name={item.name}
                                    value={item.name}
                                    checked={state[index]}
                                    onChange={() => handleOnChange(index)}
                                />
                                <label htmlFor={`custom-checkbox-${group}-${index}`}>{item.name}</label>
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    )
}

Checkboxes.propTypes = {
    items: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    group: PropTypes.string.isRequired,
};