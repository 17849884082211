import React, { useEffect, useState } from 'react'
import { useDirectus } from 'react-directus';
import { useTranslation } from 'react-i18next';

export default function LanguageSwitcher() {
    const { directus } = useDirectus();
    const { t, i18n } = useTranslation('einstellungen');


    const [language, setLanguage] = useState(null);

    const getLanguage = async () => {
        const newMe = await directus.users.me.read({ fields: ['*'] });
        setLanguage(newMe.language);
    }

    const updateLanguage = async (newLanguage) => {
        await directus.users.me.update({
            language: newLanguage
        })
    }

    const languages = [
        {
            value: 'fr',
            label: t('Französisch')
        },
        {
            value: 'it',
            label: t('Italienisch')
        },
        {
            value: 'de',
            label: t('Deutsch')
        }
    ]

    useEffect(() => {
        getLanguage();
    }, [])

    const handleChange = (e) => {
        updateLanguage(e.target.value)
        setLanguage(e.target.value)
        localStorage.setItem('language', e.target.value)
        i18n.changeLanguage(e.target.value);

    }

    return (
        <div>
            <select value={language} onChange={handleChange}>
                {languages.map((item) => {
                    return <option key={item.key} value={item.value}>{item.label}</option>
                })}
            </select>
        </div>
    )
}
