import { Entries, Entry } from "@helpers/Entries";
import i18n from '../../../translation/i18n'

const t = i18n.t.bind(i18n);


const SlotObject = new Entry([
    { key: 'date', name: 'Datum' },
    { key: 'zeit', name: 'Zeit' },
    { key: 'status', name: 'Status' },
    { key: 'region', name: 'Region' },
    { key: 'kontingent', name: 'Kontingent' },
]);

const capitalizeFirstLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
}

const formatDate = (inputDate) => {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    const date = new Date(inputDate);
    return date.toLocaleDateString('de-DE', options);
}

const changeStatus = (status) => {
    switch (status) {
        case 'open':
            return 'verfügbar';
        case 'wish':
            return 'Wunschtermin';
        case 'booked':
            return 'gebucht';
        default:
            return 'unbekannt';
    }
}


class SlotEntries extends Entries {
    constructor(rawData) {
        super(rawData); // Call the constructor of the parent class

        this.attributes = SlotObject.getAttributes();
        console.log(rawData);

        // map over rawData and change the date format
        this.flattenedData = rawData.map((item) => {
            return {
                ...item,
                zeit: capitalizeFirstLetter(item.zeit),
                date: item.date,
                status: changeStatus(item.status),
                region: item.region?.name,
            };
        });
    }
}

export { SlotEntries, SlotObject };