import React from 'react'
import { Modal } from '@containers/modal/modal'
import DatePickerImport from './DatePickerImport';
import UiBlock from '@containers/ui-block/UiBlock';
import { useTranslation } from 'react-i18next';

export default function SlotErfassenModal(props) {
    const { t } = useTranslation();
    const { onClose } = props;

    return (
        <Modal title={t('Slots importieren')} className="fullwidth" onClose={onClose}>
            <UiBlock text={'Hier kannst du neue Slots erfassen. Klicke auf den blauen Kreis, so kannst du ganztägige Slots erfassen, mit dem grünen kannst du Verfügbarkeiten am Vormittag und mit dem türkisen Verfügbarkeiten am Nachmittag erfassen. Hier werden standardmässig alle Slots mit einem Kontingent von zwei Workshops erfasst. Möchtest du parallel Workshops anbieten, dann kannst du via csv Import Slots erfassen.'} title={t('Neue Slots erfassen')}>
                <DatePickerImport />
            </UiBlock>
        </Modal >
    )
}