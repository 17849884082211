import { format, parse } from 'date-fns';
import { de } from 'date-fns/locale';


const parseFields = (fields) => {
    let parsedFields = null;
    if (fields) {
        try {
            parsedFields = { ...JSON.parse(fields) }
        } catch (error) {
            // Handle the JSON parsing error, if necessary
        }
    }

    return parsedFields;
}

const getDataOnLoad = async (collection, fields, directus, filter = null, limit = null) => {
    const items = await directus.items(collection).readByQuery(
        {
            fields: fields,
            filter: filter,
            limit: limit,
        }
    );
    return items;
};

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state,
                [action.key]: action.payload,
            }
        case 'FETCH':
            return action.payload

        default:
            return state
    }
}

function formatDate(dateString) {
    let dateObject;

    if (dateString.length === 8) {
        // Assuming format is 'dd.MM.yy'
        dateObject = parse(dateString, 'dd.MM.yy', new Date());
    } else if (dateString.length === 10) {
        // Assuming format is 'yyyy-MM-dd'
        dateObject = parse(dateString, 'yyyy-MM-dd', new Date());
    } else {
        throw new Error('Invalid date format');
    }

    const formattedDate = format(dateObject, 'EE, dd.MM.yyyy', { locale: de });
    return formattedDate;
}

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export { parseFields, getDataOnLoad, reducer, formatDate, capitalize };