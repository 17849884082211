import React, { useEffect, useState, useReducer } from 'react'
import WorkshopItem2 from '../../components/WorkshopItem2';
import { Modal } from '@containers/modal/modal'
import UiBlock from '@containers/ui-block/UiBlock';

import { useDirectus } from 'react-directus';
import { WorkshopItem } from '../../components';
import { useError } from '@context/ErrorContext';
import EditWorkshop from './EditWorkshop';
import { GeneralButtonGroup } from '../../../../components';
import Textarea from '../../../../components/input-fields/textfield/Textarea';

const initialState = []

const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_VALUE':
            const updatedWorkshops = state.workshops.map(item => {
                if (item.id === action.payload.id) {
                    return { ...item, value: action.payload.value };
                }
                return item;
            });

            return { ...state, workshops: updatedWorkshops };

        case 'UPDATE_TIME':
            const updatedWorkshops2 = state.workshops.map(item => {
                if (item.id === action.payload.id) {
                    return { ...item, time: action.payload.time };
                }
                return item;
            });

            return { ...state, workshops: updatedWorkshops2 };

        case 'UPDATE_SLOT':
            const updatedWorkshops3 = state.workshops.map(item => {
                if (item.id === action.payload.id) {
                    return { ...item, slot: action.payload.slot };
                }
                return item;
            });

            return { ...state, workshops: updatedWorkshops3 };

        case 'SET_WORKSHOPS':
            return { ...state, workshops: action.payload };

        default:
            return state;
    }
};



export default function WorkshopDetails(props) {
    const { id, hub, reloadData } = props;

    const { directus } = useDirectus();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { setErrorMessage, setSuccessMessage } = useError();

    const [initialData, setInitialData] = useState(false);
    const [slots, setSlots] = useState([]);
    const [slotIds, setSlotIds] = useState([]);
    const [options, setOptions] = useState([]);
    const [dateOptions, setDateOptions] = useState([]);
    const [workshops, setWorkshops] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [historieValue, setHistorieValue] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const [modalIsOpen3, setModalIsOpen3] = useState(false);

    const slotDate = (slotDate) => {
        const date = new Date(slotDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);

        const formattedDate = `${day}.${month}.${year}`;

        return formattedDate;
    }

    useEffect(() => {

        if (id !== undefined) {
            directus.items('anmeldungen').readOne(id, { fields: ['history'] }).then(
                (response) => {
                    const fetchedData = response;

                    const tmpHistoryData = fetchedData?.history !== undefined ? JSON.parse(fetchedData?.history) : [];

                    setHistoryData(tmpHistoryData);

                }).catch((e) => {
                    setErrorMessage('Fehler beim Laden der Daten');
                })
        }

    }, [modalIsOpen])

    const fetchSlots = async (slotIds, directus, setSlots) => {
        try {
            const response = await directus.items('slots').readByQuery({
                filter: {
                    id: {
                        _in: slotIds,
                    },
                },
                sort: 'date,-zeit',
                limit: 1000,
            });

            setSlots(response.data);
        } catch (error) {
            console.error('Error fetching slot items:', error);
        }
    };

    useEffect(() => {
        if (slotIds.length > 0) {
            fetchSlots(slotIds, directus, setSlots);
        }
    }
        , [slotIds]);

    const updateWorkshop = async (item) => {
        try {
            await directus.items('workshops').updateOne(item.id, {
                startZeit: item.startZeit,
                slot: item.slot,
            });
            console.log(`Workshop with ID ${item.id} updated successfully.`);
        } catch (error) {
            console.error(`Error updating workshop with ID ${item.id}:`, error);
        }
    };

    const updateAllWorkshops = async (data) => {
        console.log(data)
        try {
            await Promise.all(data.map(item => updateWorkshop(item)));
            console.log('All workshops updated successfully.');
        } catch (error) {
            console.error('Error updating workshops:', error);
        }
    };




    const handleSave = () => {


        if (historieValue === '') {
            setErrorMessage('Bitte einen Kommentar für die Historie eingeben');
            return;
        }


        const filteredData = state.workshops.filter(item => item.value !== undefined);

        const data = filteredData.map(item => {
            let [slotId, startZeit] = item.value.split('-');
            startZeit = startZeit ? `${startZeit.substring(0, 2)}:${startZeit.substring(2, 4)}` : ''
            console.log(item.time)
            if (item.time !== "" && item.time !== undefined) {
                startZeit = item.time
            }
            return {
                id: item.id,
                slot: slotId,
                startZeit: startZeit
            };
        })

        updateAllWorkshops(data);

        const newHistory = historyData;
        newHistory.push({ date: new Date(), message: `Workshops bearbeitet: ${historieValue}` });


        directus.items('anmeldungen').updateOne(id, {
            history: JSON.stringify(newHistory)
        }).then((response) => {
            setSuccessMessage('Anmeldung erfolgreich bestätigt');
            setModalIsOpen(false);
            reloadData();


        }).catch((e) => {
            setErrorMessage('Anmeldung konnte nicht bestätigt werden');
        })



    }

    useEffect(() => {


        if (slots.length > 0) {

            let tmpDateOptions = slots.flatMap((slot) => {
                return { value: slot.id, label: slotDate(slot.date) + ' - ' + slot.zeit }
            })

            console.log(tmpDateOptions)
            tmpDateOptions.unshift({ value: '', label: 'Slot auswählen' });

            setDateOptions(tmpDateOptions)




            let options = slots.flatMap((slot) => {
                if (slot.zeit === 'vormittag') {
                    // Add morning slots (e.g., 08:30 and 10:30)
                    const morningSlots = [
                        { value: slot.id + '-0830', label: slotDate(slot.date) + ' - 08:30-10:00' },
                        { value: slot.id + '-1030', label: slotDate(slot.date) + ' - 10:15-11:45' },
                    ];

                    if (slot.kontingent === "4") {
                        morningSlots.push(
                            { value: slot.id + '-0830-2', label: slotDate(slot.date) + ' - 08:30-10:00' },
                            { value: slot.id + '-1030-2', label: slotDate(slot.date) + ' - 10:15-11:45' },
                        );
                    }
                    return [...morningSlots];
                }

                if (slot.zeit === 'nachmittag') {
                    // Add morning slots (e.g., 08:30 and 10:30)
                    const morningSlots = [
                        { value: slot.id + '-1330', label: slotDate(slot.date) + ' - 13:30-15:00' },
                        { value: slot.id + '-1530', label: slotDate(slot.date) + ' - 15:15-16:45' },
                    ];
                    if (slot.kontingent === "4") {
                        morningSlots.push(
                            { value: slot.id + '-1330-2', label: slotDate(slot.date) + ' - 13:30-15:00' },
                            { value: slot.id + '-1530-2', label: slotDate(slot.date) + ' - 15:15-16:45' }
                        );
                    }
                    return [...morningSlots];
                }

                return options;
            });


            options.unshift({ value: '', label: 'Zeitfenster auswählen' });

            setOptions(options);
        }
    }, [slots]);

    useEffect(() => {

        if (id !== undefined) {
            directus.items('anmeldungen').readOne(id, { fields: ['slots.*', 'workshops.*', 'workshops.slot.*'] }).then(
                (response) => {
                    const fetchedData = response;
                    fetchedData.slot_ids = fetchedData.slots.map((slot) => slot.slots_id);

                    setInitialData(fetchedData.slots)
                    setWorkshops(fetchedData.workshops)
                    setSlotIds(fetchedData.slot_ids);




                    const tmpWorkshops = fetchedData.workshops.map(item => {
                        const date = item.slot && item.slot.id ? item.slot.id : '';

                        let startZeit = item.startZeit ? item.startZeit : '';
                        if (startZeit) {
                            startZeit = startZeit.substring(0, 5).replace(':', '');
                        }

                        item.value = `${date}-${startZeit}`.trim();
                        return item;
                    });


                    dispatch({ type: 'SET_WORKSHOPS', payload: tmpWorkshops })

                }).catch((e) => {
                })
        }

    }, [modalIsOpen, modalIsOpen2, modalIsOpen3])

    const handleSelectChange = (event, item) => {

        const updatedOptions = [...options];

        updatedOptions.forEach(option => {
            if (option.workshop === item) {
                option.workshop = undefined;
                option.selected = false;
            }
        });

        const newSelectedIndex = updatedOptions.findIndex(option => option.value === event.target.value);

        if (newSelectedIndex !== -1) {
            updatedOptions[newSelectedIndex].workshop = item;
        }


        setOptions(updatedOptions);
    };

    useEffect(() => {
        reloadData();
        console.log('reloadData')
    }, [modalIsOpen2, modalIsOpen3, modalIsOpen])


    return (
        <div className="overview-wrapper">
            <h2>Workshops</h2>
            {
                workshops.map((workshop, index) => (
                    <WorkshopItem
                        key={`beschreibung-${workshop.bezeichnung}`}
                        workshop={workshop}
                        index={index}
                        options={options}
                        handleSelectChange={handleSelectChange}
                        onClick={() => setModalIsOpen2(index)}
                    >
                    </WorkshopItem>
                ))




            }

            <GeneralButtonGroup buttons={[{ text: 'Neuer Workshop erstellen', classNames: 'button--inline', handleOnClick: () => setModalIsOpen3(true) }, { text: 'Workshops-Slot Zuteilung', classNames: 'button--inline', handleOnClick: () => setModalIsOpen(true) }]} />
            {
                modalIsOpen ?
                    <Modal title={'Workshops bearbeiten'} inline={true} onClose={() => setModalIsOpen(false)}>
                        <UiBlock title={'Verknüpfte Workshops'} >

                            {
                                workshops.map((workshop, index) => (
                                    <WorkshopItem2
                                        key={`beschreibung-${workshop.bezeichnung}`}
                                        workshop={workshop}
                                        index={index}
                                        options={options}
                                        handleSelectChange={handleSelectChange}
                                        value={state.workshops[index]?.value}
                                        dispatch={dispatch}
                                        dateOptions={dateOptions}

                                    >

                                    </WorkshopItem2>
                                ))


                            }

                            <Textarea
                                value={historieValue || ''}
                                onChange={(value) =>
                                    setHistorieValue(value)
                                }
                                placeholder={'Kommentar für die Historie'}
                            />

                        </UiBlock>
                        <GeneralButtonGroup buttons={[{ text: 'Speichern', handleOnClick: () => handleSave(), classNames: 'button--inline' }, { text: 'Abbrechen', handleOnClick: () => setModalIsOpen(false), classNames: 'button--inline' }]} />

                    </Modal> : null
            }

            {
                modalIsOpen2 !== false ?
                    <Modal title={'Workshop bearbeiten'} inline={true} onClose={() => setModalIsOpen2(false)}>
                        <UiBlock>
                            <EditWorkshop
                                id={workshops[modalIsOpen2]?.id}
                                handleModalClose={setModalIsOpen2}
                                anmeldungId={id}
                            ></EditWorkshop>
                        </UiBlock>
                    </Modal> : null
            }

            {
                modalIsOpen3 !== false ?
                    <Modal title={'Workshop erstellen'} inline={true} onClose={() => setModalIsOpen3(false)}>
                        <UiBlock>
                            <EditWorkshop
                                createWorkshop={true}
                                anmeldungId={id}
                                hub={hub}
                                handleModalClose={setModalIsOpen3}></EditWorkshop>
                        </UiBlock>
                    </Modal> : null
            }


        </div>)
}
