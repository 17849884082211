import { ContentWrapper } from '@containers';
import { useDirectus } from 'react-directus';
import { useEffect, useState } from 'react';
import 'react-data-grid/lib/styles.css';
import { PlzEntries } from '../helpers';
import { BasicTable } from '@containers/table/BasicTable';
import './plz-overview.scss'
import UiBlock from '@containers/ui-block/UiBlock';

import { getDataOnLoad } from '@helpers/general-helpers';
import { useError } from '../../../context/ErrorContext';

import { getCollectionHubItems, getCollectionRegionenItems } from '../helpers/plz-helpers';

const PlzOverviewPage = () => {
    const { setErrorMessage, setSuccessMessage } = useError();

    const { directus } = useDirectus();
    const [kantonFilter, setKantonFilter] = useState('');
    const [ortFilter, setOrtFilter] = useState('');
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [entriesObject, setEntriesObject] = useState(null);
    const [collectionHubItems, setCollectionHubItems] = useState([]);
    const [collectionRegionenItems, setCollectionRegionenItems] = useState([]);
    const [collection, setCollection] = useState(null);

    const [searchTimeout, setSearchTimeout] = useState(null);
    const [emptyHubItems, setEmptyHubItems] = useState(null);

    const collectionName = 'plz';
    const fields = ['*', 'hub.*', 'region.*'];
    const filter = {
        ...(kantonFilter !== '' ? { kanton: { _eq: kantonFilter } } : {}),
        ...(ortFilter !== '' ? { ort: { _contains: ortFilter } } : {}),
        ...(kantonFilter === '' && ortFilter === '' ? { hub: { _null: true } } : {}),
    }
    const limit = (kantonFilter === '' && ortFilter === '' ? 10000 : 1500);


    const getData = async () => {
        getDataOnLoad(collectionName, fields, directus, filter, limit)
            .then((items) => {
                if (kantonFilter === '' && ortFilter === '') setEmptyHubItems(items.data.length);
                const leiterpersonenItems = new PlzEntries(items.data.slice(0, 1000));
                setCollection(leiterpersonenItems);
                setEntriesObject(leiterpersonenItems);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        getCollectionHubItems(directus, setCollectionHubItems);
        getCollectionRegionenItems(directus, setCollectionRegionenItems);

    }, []);

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        const newTimeout = setTimeout(() => {
            getData();
        }, 500);

        setSearchTimeout(newTimeout);

        return () => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
        };
    }, [kantonFilter, ortFilter]);

    const setPLZ = async () => {
        const idValues = entriesObject.getData().map(item => item.id);


        const newValues = {
            hub: selectedItem !== '' ? selectedItem : null,
            region: selectedRegion !== '' ? selectedRegion : null,
        };

        directus.items('plz').updateMany(idValues, newValues).then(
            (response) => {
                getData();
                setSuccessMessage('Update erfolgreich. Es wurden ' + response.data.length + ' Einträge aktualisiert.');
            }
        ).catch(
            (error) => {
                setErrorMessage('Update fehlgeschlagen: ' + error.message);
            }
        );

    };

    return (
        <ContentWrapper title={'Postleitzahlen'}>
            <UiBlock
                text={'Hier kannst du Postleitzahlen und Hubs verknüpfen. Wähle einen Kanton und/oder Ort aus, um die Liste zu filtern. Wenn du einen Hub auswählst und auf Update klickst, werden die Postleitzahlen mit dem Hub verknüpft.'}
                title={'Postleitzahlen und Hubs'}>
            </UiBlock>
            <div className='plz-input-container'>
                <div className="plz-input-wrapper">
                    <input
                        type="text"
                        id="kantonFilter"
                        value={kantonFilter}
                        onChange={(e) => setKantonFilter(e.target.value)}
                        placeholder='Kanton'
                    />

                    <input
                        type="text"
                        id="ortFilter"
                        value={ortFilter}
                        onChange={(e) => setOrtFilter(e.target.value)}
                        placeholder='Ort'

                    />
                </div>
                <div className="plz-input-wrapper">

                    <select
                        id="selectItem"
                        value={selectedItem}
                        onChange={(e) => setSelectedItem(e.target.value)}
                    >
                        <option value="">Hub auswählen</option>
                        {collectionHubItems.map(item => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>

                    <select
                        id="selectItem"
                        value={selectedRegion}
                        onChange={(e) => setSelectedRegion(e.target.value)}
                    >
                        <option value="">Region auswählen</option>
                        {collectionRegionenItems.map(item => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                    <button className="button" onClick={() => setPLZ()}>Update</button>

                </div>
            </div>
            {emptyHubItems > 0 ?
                <div className="plz-warning">
                    Es gibt noch <b>{emptyHubItems}</b> Postleitzahlen ohne Hub. Hier werden nur die ersten 100 angezeigt.
                </div>
                : <div className="plz-warning plz-warning--green">
                    Alle Postleitzahlen sind einem Hub zugewiesen.
                </div>
            }

            {collection ? <BasicTable entries={collection} /> : null}
        </ContentWrapper>
    );
};

export default PlzOverviewPage;