import React from 'react'
import { Modal } from '@containers/modal/modal'
import AddUser from '@pages/user/containers/AddUser'
import UiBlock from '@containers/ui-block/UiBlock'

import { useParams } from 'react-router-dom'

export default function AddUserModal() {
    const { id, userId } = useParams();

    return (
        <Modal title="Neuer Benutzer">
            <UiBlock
                text={false ? 'Hier kannst du einen User bearbeiten.' : 'Hier kannst du einen neuen User erfassen. Definiere Name, Vorname und Email-Adresse. Die Person wird eine Email mit einem Einladungslink erhalten und kann das Passwort setzen.'}
                title={false ? 'User bearbeiten' : 'Neue User erfassen'}>
                <AddUser isEditPage={userId} hubId={id} userId={userId} />
            </UiBlock>
        </Modal>
    )
}
