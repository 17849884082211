import { useDirectus } from 'react-directus';
import { useEffect, useState } from 'react';

// TODO
import { BasicTable } from '@containers/table/BasicTable';
import ContentWrapper from '@containers/content-wrapper/ContentWrapper';
import { HubEntries } from '../helpers/HubEntries';
const Hubs = () => {
    const { directus } = useDirectus();
    const [entriesObject, setEntriesObject] = useState(null);



    const getDataOnLoad = async () => {
        const items = await directus.items('hubs').readByQuery();

        const myObject = new HubEntries(items.data);
        myObject.getData();
        setEntriesObject(myObject);
    };
    useEffect(() => {
        getDataOnLoad();
    }, []); // eslint-disable-line

    return <ContentWrapper title={'Hubs'} >
        {entriesObject ?
            <BasicTable entries={entriesObject} filter={['name', 'ort', 'email', 'verantwortlich']} />
            : null}
    </ContentWrapper>;
};

export default Hubs;