import AddLeiterperson from '../containers/AddLeiterperson';

import ContentWrapper from '@containers/content-wrapper/ContentWrapper';
import UiBlock from '@containers/ui-block/UiBlock';

const NeueLeiterperson = (props) => {
    const { isEditPage } = props;

    return <ContentWrapper title={isEditPage ? 'Leiterperson bearbeiten' : 'Leiterperson erfassen'} addButton={false} backButton={true} >
        <UiBlock text={isEditPage ? 'Hier kannst du eine Leiterperson bearbeiten.' : 'Hier kannst du eine neue Leiterperson erfassen.'} title={isEditPage ? 'Leiterperson bearbeiten' : 'Neue Leiterperson erfassen'}>
            <AddLeiterperson isEditPage={isEditPage} /></UiBlock>
    </ContentWrapper>;
};

export default NeueLeiterperson;