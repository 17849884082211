import { Entries, AnmeldungObject } from "./Entries";

class AnmeldungEntries extends Entries {
    constructor(rawData) {
        super(rawData); // Call the constructor of the parent class

        this.attributes = AnmeldungObject.getAttributes();
    }
}

export { AnmeldungEntries };