import AddHub from '../containers/AddHub';
import ContentWrapper from '@containers/content-wrapper/ContentWrapper';
import UiBlock from '@containers/ui-block/UiBlock';

import UserList from '../containers/UserList';


const SingleHub = (props) => {
    const { isEditPage } = props;

    return <ContentWrapper title={isEditPage ? 'Hub bearbeiten' : 'Hub erfassen'} addButton={false} >
        <UiBlock text={isEditPage ? 'Hier kannst du einen Hub bearbeiten.' : 'Hier kannst du einen neue Hub erfassen.'} title={isEditPage ? 'Hub bearbeiten' : 'Neuen Hub erfassen'}>
            <AddHub isEditPage={isEditPage} /></UiBlock>
        {isEditPage
            ? <UiBlock
                text={'Hier kannst du Benutzer hinzufügen und bearbeiten.'}
                title={'Verknüpfte Benutzer'}>
                <UserList />
            </UiBlock>
            : null}

    </ContentWrapper>;
};

export default SingleHub;