import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

import MapPreview from './Map';

export default function Standort(props) {
    const { data } = props;
    const [location, setLocation] = useState(null);

    useEffect(() => {
        // Function to fetch coordinates from Nominatim
        const fetchCoordinates = async () => {
            try {
                const url = `https://nominatim.openstreetmap.org/search.php?street=${data.adresse}&city=${data.ort}&postalcode=${data.plz}&countrycodes=ch&format=jsonv2`;
                const response = await fetch(url);
                const jsonResponse = await response.json();

                if (jsonResponse.length > 0) {
                    setLocation([parseFloat(jsonResponse[0].lat), parseFloat(jsonResponse[0].lon)]);
                }

            } catch (error) {
                console.log('Error:', error);
            }
        };


        fetchCoordinates();

    }, []);




    return (
        <div>
            <h2>Standort</h2>
            <MapPreview geojson={JSON.parse(data.platz)} position={JSON.parse(data.anhaenger_standort)} location={location} />
        </div>
    )
}

Standort.propTypes = {
    data: PropTypes.object.isRequired
};