import React, { useEffect, useState } from 'react'
import './kalender.scss'


import AnmeldungItem from './AnmeldungItem';
import SlotItem from './SlotItem';
export default function Kalender(props) {
    const { uuid, id } = props;

    const [data, setData] = useState([]);
    const [allSlots, setAllSlots] = useState([]);
    function getData(uuid) {
        return new Promise((resolve, reject) => {
            const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

            // Assuming you have the 'uuid' value stored in a variable named 'uuidValue'
            const apiUrl = API_ENDPOINT + '/custom-endpoint'; // Change this to your actual API endpoint


            const url = apiUrl + '/leiterpersonen';
            const data = { uuid };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    resolve(data); // Resolve the promise with the response data
                })
                .catch(error => {
                    reject(error); // Reject the promise with the error
                });
        });
    }

    function getAllSlots(uuid) {
        return new Promise((resolve, reject) => {
            const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

            // Assuming you have the 'uuid' value stored in a variable named 'uuidValue'
            const apiUrl = API_ENDPOINT + '/custom-endpoint'; // Change this to your actual API endpoint


            const url = apiUrl + '/leiterpersonen-slots';
            const data = { uuid };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    resolve(data); // Resolve the promise with the response data
                })
                .catch(error => {
                    reject(error); // Reject the promise with the error
                });
        });
    }

    const dataLoader = () => {
        getData(uuid)
            .then(responseData => {
                const result = responseData.map((anmeldung) => {
                    return {
                        ...JSON.parse(anmeldung.fields),
                        slots: anmeldung.slots
                    }
                })
                setData(result)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const slotsLoader = () => {
        getAllSlots(uuid)
            .then(responseData => {

                setAllSlots(responseData)
            })
            .catch(error => {
                console.error('Error:', error);
            });

    }

    useEffect(() => {
        dataLoader();
        slotsLoader();
    }, []);

    return (
        <div className="kalender">
            <h2>Anmeldungen</h2>
            {data.map((anmeldung) => (
                <AnmeldungItem anmeldung={anmeldung} key={anmeldung.id} uuid={uuid} id={id} dataLoader={dataLoader} />)
            )}
            {data.length === 0 && <p>Noch keine Anmeldungen vorhanden</p>}
            <h2>Slots</h2>
            {allSlots.map((slot) => (
                <SlotItem slot={slot} key={slot.id} uuid={uuid} id={id} dataLoader={dataLoader} />)
            )}
            {data.length === 0 && <p>Noch keine Slots vorhanden.</p>}

        </div>
    )
}
