
import './login.scss';
import LoginForm from '../../containers/login-form/LoginForm';

import { useEffect } from 'react';
import { useDirectus } from 'react-directus';

const Login = () => {

    const { directus } = useDirectus();


    useEffect(() => {
        localStorage.clear();
    }, [directus])


    return <div className="login-page">
        <LoginForm />
    </div>;
};

export default Login;