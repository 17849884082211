import React, { useEffect, useState } from 'react'
import AnmeldungItem from './AnmeldungItem'
import { useTranslation } from 'react-i18next';

export default function LeiterpersonenZuteilung(props) {

    const { anmeldung } = props;
    const { t } = useTranslation('anmeldung');

    console.log(anmeldung)

    return (
        <div className="overview-wrapper">
            <h2>{t('Leiterpersonen')}</h2>
            <AnmeldungItem anmeldung={anmeldung} key={anmeldung.id} />
        </div>
    )
}
