import ContentWrapper from '@containers/content-wrapper/ContentWrapper';
import UiBlock from '@containers/ui-block/UiBlock';
import AddRegion from '../containers/AddRegion';
const NewUser = (props) => {
    const { isEditPage } = props;

    return <ContentWrapper title={isEditPage ? 'Region bearbeiten' : 'Region erfassen'} addButton={false}>
        <UiBlock text={isEditPage ? 'Hier kannst du eine Region bearbeiten.' : 'Hier kannst du eine neue Region erfassen.'} title={isEditPage ? 'Region bearbeiten' : 'Neue Region erfassen'}>
            <AddRegion isEditPage={isEditPage} /></UiBlock>
    </ContentWrapper>;
};

export default NewUser;