import ContentWrapper from '../../containers/content-wrapper/ContentWrapper';
import AnmeldungPreview from './containers/AnmeldungPreview';
import { useTranslation } from 'react-i18next';

const SingleAnmeldung = (props) => {
    const { isEditPage } = props;
    const { t } = useTranslation('anmeldung');

    return <ContentWrapper title={t('Anmeldung')} addButton={false} backButton={true} >
        <AnmeldungPreview isEditPage={isEditPage} />
    </ContentWrapper>;
};

export default SingleAnmeldung;