import ChangePassword from '@containers/change-password/ChangePassword';
import ContentWrapper from '@containers/content-wrapper/ContentWrapper';
import UiBlock from '@containers/ui-block/UiBlock';

import HubSettings from './components/HubSettings';
import { useTranslation } from 'react-i18next';

import LanguageSwitcher from './components/LanguageSwitcher';

const Einstellungen = () => {
    const { t } = useTranslation('einstellungen');



    return <ContentWrapper title={t('Einstellungen')} addButton={false} >

        <UiBlock text={t('einstellungen_text')} title={t('Auswählen der Slots')} >
            <HubSettings />
        </UiBlock>
        <UiBlock text={t('einstellungen_change_password')} title={t('Passwort ändern')} >
            <ChangePassword />
        </UiBlock>

        {/*
            <UiBlock text={'Hier kannst du 2FA aktivieren oder deaktivieren'} title={'2FA'} >
                <TwoFA />
            </UiBlock>
*/}

        <UiBlock text={t('Hier kannst du die Sprache ändern')} title={t('Sprache')} >
            <LanguageSwitcher />
        </UiBlock>

    </ContentWrapper>;
};

export default Einstellungen;