import { Link, useLocation } from "react-router-dom";
import './table.scss';
import PropTypes from 'prop-types';
const Table = (props) => {
    const location = useLocation();

    const { entries } = props;

    return <div className="table-wrapper">
        {entries ?
            <ul className="table">
                <li className="table__row table__row--headline">
                    <div>
                        {entries.getFields().map((field) => (
                            <span key={field.key} className="table__cell table__cell--headline">
                                {field.name}
                            </span>
                        ))}
                    </div>

                </li>
                {entries.getData().map((item, i) => {
                    return <li className={'table__row'} key={item.id}><Link className={'add-button--wrapper'} to={"user/" + item.id} state={{ background: location }}>
                        {entries.getFields().map((obj) => {
                            return <span key={obj.key}>{item[obj.key]}</span>
                        })}
                    </Link></li>
                })}</ul>
            : null}


    </div>;

};

Table.propTypes = {
    entries: PropTypes.object.isRequired
};

export default Table;