import { Entries, Entry } from "@helpers/Entries";

const HubObject = new Entry([
    { key: 'name', name: 'Name' },
    { key: 'adresse', name: 'Adresse' },
    { key: 'plz', name: 'PLZ' },
    { key: 'ort', name: 'Ort' },
    { key: 'verantwortlich', name: 'Verantwortliche Person' },
    { key: 'email', name: 'Email' },
]);


class HubEntries extends Entries {
    constructor(rawData) {
        super(rawData); // Call the constructor of the parent class

        this.attributes = HubObject.getAttributes();
    }
}

export { HubEntries, HubObject };