import React from 'react'
import { Modal } from '@containers/modal/modal'
import { SlotsImport } from '.';
import UiBlock from '@containers/ui-block/UiBlock';

export default function TFAModal(props) {

    const { onClose } = props;

    const text = <div>Hier kannst du mehrere Slots auf einmal importieren.Und hier kannst du eine <a href="/import.csv" style={{ zIndex: 1000 }} download>Beispieldatei herunterladen</a>. Bearbeite diese Datei, speichere Sie wieder als CSV und lade sie hoch.
    </div>

    return (
        <Modal title={'Slots importieren'} onClose={onClose}>
            <UiBlock text={text} title={'Slots importieren'} >
                <SlotsImport />
            </UiBlock>
        </Modal>
    )
}