import React, { useEffect, useState } from 'react';
import SlotItem from './SlotItem';
import { useDirectus } from 'react-directus';
import PropTypes from 'prop-types';

export default function AnmeldungItem({ anmeldung }) {
    const { directus } = useDirectus();
    const [slots, setSlots] = useState([]);
    const [leiterpersonen, setLeiterpersonen] = useState([]);

    const getSlots = async (slotIds) => {

        if (slotIds.length === 0) return;

        try {
            const response = await directus.items('slots').readByQuery({
                filter: { id: { _in: slotIds } },
                fields: ['*']
            });
            setSlots(response.data);
        } catch (e) {
            console.error('Error fetching slots:', e);
        }
    }

    const getLeiterpersonen = async () => {

        try {
            const response = await directus.items('leiterpersonen').readByQuery();
            const tmpLeiterersonen = response.data.map((leiterperson) => { return { id: leiterperson.id, ...JSON.parse(leiterperson.fields) } });
            console.log(tmpLeiterersonen)
            setLeiterpersonen(tmpLeiterersonen);
        } catch (e) {
            console.error('Error fetching slots:', e);
        }
    }

    useEffect(() => {

        getSlots(anmeldung.slots);
        getLeiterpersonen();
    }, [anmeldung]);

    return (
        <div className="kalender__anmeldung-item">
            <div className="label">
                {anmeldung.schule}, {anmeldung.ort}
            </div>
            <div>
                {slots.map((slot) => <SlotItem slot={slot} key={slot.id} leiterpersonen={leiterpersonen} />)}
            </div>
        </div>
    );
}

AnmeldungItem.propTypes = {
    anmeldung: PropTypes.object.isRequired,
};
