import React, { useState, useEffect } from "react"
import { Calendar } from "react-multi-date-picker"
import colors from "react-multi-date-picker/plugins/colors"

import './date-picker.scss';

import PropTypes from 'prop-types'

export default function DatePicker({ slots, onChange }) {



    const [props, setProps] = useState({
        value: [],
    });

    const customColors = {
        blue: "ganztag",
        red: "vormittag",
        green: "nachmittag",
    };

    const monthNames = [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
    ];

    const dayNamesShort = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

    useEffect(() => {
        if (!slots) {
            setProps((prevProps) => ({
                ...prevProps,
                value: [],
            }));
        }
    }, [slots]);

    const handlePropsChange = (newProps) => {
        setProps(newProps);
        const modifiedData = newProps.value.flatMap((obj) => {
            if (obj.color === 'ganztag') {
                return [
                    { date: new Date(obj).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' }), color: 'vormittag' },
                    { date: new Date(obj).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' }), color: 'nachmittag' },
                ];
            }
            return [{ date: new Date(obj).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' }), color: obj.color }];
        }).sort((a, b) => new Date(a.date) - new Date(b.date));
        onChange(modifiedData);
    };
    return (
        <div>
            <Calendar
                {...props}
                multiple={true}
                onPropsChange={handlePropsChange}
                highlightToday={false}
                plugins={[
                    colors({ colors: Object.values(customColors), position: "left" }),
                ]}
                weekDays={dayNamesShort}
                months={monthNames}
            />
        </div>
    );
}

DatePicker.propTypes = {
    slots: PropTypes.array,
    onChange: PropTypes.func.isRequired,
}