import { ContentWrapper } from '@containers';
import { AddSlots } from '../containers/';
import EditSlot from '../containers/EditSlot';
import { useTranslation } from 'react-i18next';

const SingleSlotPage = (props) => {
    const { isEditPage } = props;
    const { t } = useTranslation();

    return <ContentWrapper title={isEditPage ? t('Slots bearbeiten') : t('Slots erfassen')} addButton={false} backButton={true} >
        {isEditPage ?
            <EditSlot /> :
            <AddSlots />}
    </ContentWrapper>;
};

export default SingleSlotPage;