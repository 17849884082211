Object.defineProperty(exports, "__esModule", { value: true });

exports.default = function (e) {
    return {
        type: "mapDays",
        fn: function () {
            return function (a) {
                let n = a.date;

                let year = n.year;
                let month = String(n.month).padStart(2, '0'); // Zero padding on month
                let day = String(n.day).padStart(2, '0'); // Zero padding on day

                let dateToCheck = year + '-' + month + '-' + day; // Create date string without time
                if (
                    Array.isArray(e) &&
                    e.some(function (dateFromArray) {
                        return dateFromArray.date === dateToCheck;
                    })
                ) {
                    // Find the object with matching date and get the className based on dateFromArray.zeit
                    let matchedDate = e.find(function (dateFromArray) {
                        return dateFromArray.date === dateToCheck;
                    });

                    // Set the className based on dateFromArray.zeit
                    let className;
                    if (matchedDate.zeit === "vormittag") {
                        className = "highlight highlight-vormittag"; // Set className for "VM" (morning)
                    } else if (matchedDate.zeit === "nachmittag") {
                        className = "highlight highlight-nachmittag"; // Set className for "NM" (afternoon)
                    } else {
                        className = "highlight highlight-ganztag"; // Default className if "zeit" doesn't match any specific case
                    }

                    return { className: className };
                }
            };
        },
    };
};
