import React, { useState, useEffect } from 'react'
import UiBlock from '@containers/ui-block/UiBlock'
import { useDirectus } from 'react-directus';
import QRCode from 'qrcode.react';

import { Textfield, Button } from '@components';
import { MessageBox } from '@components/message-box';
import { useNavigate } from 'react-router-dom';
export default function ActivateTFA() {
    const { directus } = useDirectus();

    const [msg, setMsg] = useState(null);
    const [err, setErr] = useState(null);


    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [otpauthurl, setOtpauthurl] = useState(false);
    const [secret, setSecret] = useState(false);

    const navigate = useNavigate();



    const [twoFA, set2FA] = useState(false);

    const getUser = async () => {
        const me = await directus.users.me.read();
        console.log(me.tfa_secret)
        if (me.tfa_secret != null) {
            set2FA(true);
        }
    }

    useEffect(() => {
        getUser();
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!otpauthurl) {
            console.log('generate OTP');
            directus.users.me.tfa.generate(password).then(
                (response) => {
                    console.log(response);
                    console.log(response.otpauth_url);
                    setOtpauthurl(response.otpauth_url);
                    setSecret(response.secret);
                    setMsg('Bitte scannen Sie den QR-Code mit Ihrer 2FA-App und geben Sie den Code ein.');
                }).catch((e) => {
                    setMsg('Fehler beim Generieren des OTP: ' + e.message);
                    setErr(true);
                })
        } else {

            directus.users.me.tfa.enable(secret, otp).then(
                (response) => {
                    setMsg('2FA erfolgreich aktiviert.');
                    navigate('/app/dashboard');

                }).catch((e) => {
                    setMsg('Fehler beim Aktivieren der 2FA: ' + e.message);
                    setErr(true);
                })
        }


    }

    return (
        <UiBlock text={'Um die bikecontrol Plattform verwenden zu können, musst du die 2-Faktor-Authentifizierung aktivieren. '}
            title={'2FA aktivieren'} >
            <div className={'change-password'}>
                <p>
                    Dazu musst du anschliessend den QR Code mit einer 2FA-App scannen und den Code eingeben. Falls du noch keine 2FA-App hast, empfehlen wir dir die Authenticator App von Microsoft. Hier findest du die Links zu den Apps: <a target="_blank" href="https://www.microsoft.com/de-ch/security/mobile-authenticator-app">Authenticator Apps</a></p>
                <form onSubmit={e => { handleSubmit(e) }}>


                    {otpauthurl ?
                        <QRCode style={{ marginBottom: 15 }} value={otpauthurl} />

                        : null}
                    {!otpauthurl ?
                        <Textfield className="small" value={password} onChange={setPassword} placeholder="Passwort" type="password" />
                        :
                        null
                    }

                    {otpauthurl ?
                        <Textfield className="small" value={otp} onChange={setOtp} placeholder="One-Time-Password" type="text" />
                        :
                        null
                    }

                    <Button
                        type='submit'
                        value={twoFA ? 'deaktivieren' : 'aktivieren'}
                        className='small'
                    />
                </form>
            </div>
            <MessageBox
                msg={msg}
                clearMsg={() => setMsg(null)}
                error={err}
                clearError={() => setErr(null)}
            />
        </UiBlock >
    )
}