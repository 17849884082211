import { useNavigate } from "react-router-dom";
import './modal.scss';
import PropTypes from 'prop-types';

export const Modal = (props) => {
    const { children, title, className, inline, onClose } = props;
    const navigate = useNavigate();

    const onCloseDefault = () => {
        navigate(-1)
    }

    const handleClose = onClose || onCloseDefault;


    return (
        <div className={`dk-modal-wrapper ${className}`}>
            <div className="dk-modal">
                <div className="dk-modal__title">
                    <h1>{title}</h1><button className={'close-button'} onClick={onClose}><img src={process.env.PUBLIC_URL + '/assets/close.svg'} alt="arrow-filter" /></button>
                </div>
                <div className="dk-modal__content">
                    {children}

                </div>
            </div>
        </div>
    );
};

// set default props
Modal.defaultProps = {
    className: '',
    onClose: null
};

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClose: PropTypes.func
};