import React, { useState, useReducer, useEffect } from 'react';
import { useDirectus } from 'react-directus';
import { useParams, useNavigate } from 'react-router-dom';
import { RegionenObject } from '../helpers';

import { Textfield } from '@components';
import ButtonGroup from '../../../components/input-fields/button/ButtonGroup';
import { useError } from '../../../context/ErrorContext';
import { reducer } from '@helpers/general-helpers';

const initialState = RegionenObject.getInitialState();

function AddRegionen(props) {
    const [collectionHubItems, setCollectionHubItems] = useState([]);
    const { isEditPage } = props;
    const { setErrorMessage, setSuccessMessage } = useError();

    let { id } = useParams();
    const navigate = useNavigate();
    const { directus } = useDirectus();
    const [reducerState, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        getCollectionHubItems();
    }, []);

    const deleteItem = async () => {
        await directus.items('regionen').deleteOne(id).then(
            (response) => {
                setSuccessMessage('Region wurde erfolgreich gelöscht.');
                navigate('/app/regionen');
            }
        ).catch((e) => {
            setErrorMessage(e.message);
        }
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isEditPage) {
            directus.items('regionen').updateOne(id, { ...reducerState }).then(
                (response) => {
                    setSuccessMessage('Region wurde erfolgreich aktualisiert.');
                }).catch((e) => {
                    setErrorMessage(e.message);

                })
        } else {

            directus.items('regionen').createOne({ ...reducerState }).then(
                (response) => {
                    setSuccessMessage('Region wurde erfolgreich erstellt.');
                    navigate(`/app/regionen/bearbeiten/${response.id}`)
                }).catch((e) => {
                    setErrorMessage(e.message);
                })
        }

    }

    useEffect(() => {
        if (id !== undefined && isEditPage) {
            directus.items('regionen').readOne(id).then(
                (response) => {
                    const fetchedData = response;
                    const mergedData = { ...initialState, ...fetchedData }
                    dispatch({
                        type: 'FETCH',
                        payload: mergedData
                    })

                }).catch((e) => {
                    setErrorMessage(e.message);
                })
        }
    }, [directus, id, isEditPage])


    const getCollectionHubItems = async () => {
        const hubItems = await directus.items('hubs').readByQuery();
        const convertedData = hubItems.data.map(item => ({
            id: item.id,
            name: JSON.parse(item.fields).name
        }));
        setCollectionHubItems(convertedData);
    };

    const handleSelectChange = (value) => {
        console.log(value)
        dispatch({
            type: 'UPDATE',
            payload: value,
            key: 'hub',
        })
    }

    return (
        <div className={'base-container'}>
            <form onSubmit={e => { handleSubmit(e) }}>

                <Textfield
                    className="small"
                    value={reducerState.name}
                    onChange={(value) =>
                        dispatch({
                            type: 'UPDATE',
                            payload: value,
                            key: 'name',
                        })
                    }
                    placeholder='Name'
                    key='name'
                />

                <select
                    id="selectItem"
                    value={reducerState.hub}
                    onChange={(e) => handleSelectChange(e.target.value)}
                >
                    <option value="">Hub auswählen</option>
                    {collectionHubItems.map(item => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>

                <ButtonGroup deleteItem={deleteItem} />
            </form>
        </div>
    )
}

AddRegionen.defaultProps = {
    isEditPage: false,
};

export default AddRegionen;