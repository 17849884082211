import React, { useState, useEffect } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import "./slot-auswahl.scss";
import dateHighlighter from "./dateHighlighter";
import { useDirectus } from "react-directus";
import formatDate from '@helpers/formatDate';

import { useTranslation } from 'react-i18next';

export default function SlotSelection(props) {

    const { t } = useTranslation('anmeldung'); // Initialize the translation hook

    const { availableSlots, handleSlotSelection } = props;

    const [initialViewDate, setInitialViewDate] = useState();
    const [calenderSlots, setCalenderSlots] = useState([]);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const monthNames = [
        t("Januar"),
        t("Februar"),
        t("März"),
        t("April"),
        t("Mai"),
        t("Juni"),
        t("Juli"),
        t("August"),
        t("September"),
        t("Oktober"),
        t("November"),
        t("Dezember"),
    ];

    const dayNamesShort = [t("So"), t("Mo"), t("Di"), t("Mi"), t("Do"), t("Fr"), t("Sa")];

    function isDateBetween(dateToCheck, startDate, endDate) {
        const timestampToCheck = new Date(dateToCheck).getTime();
        const timestampStart = new Date(startDate).getTime();
        const timestampEnd = new Date(endDate).getTime();

        return timestampToCheck >= timestampStart && timestampToCheck <= timestampEnd;
    }


    const handleDateChange = (dates) => {
        setSelectedDates(dates);

    };

    useEffect(() => {

        if (availableSlots) {

            const hasOppositeZeitEntry = (date, zeit) => {
                const oppositeZeit = zeit === "vormittag" ? "nachmittag" : "vormittag";
                return availableSlots.some((entry) => entry.date === date && entry.zeit === oppositeZeit);
            };

            const calenderSlots = [];

            for (const entry of availableSlots) {
                const { date, zeit } = entry;

                if (hasOppositeZeitEntry(date, zeit)) {
                    calenderSlots.push({
                        id: entry.id,
                        hub: entry.hub,
                        status: entry.status,
                        date: entry.date,
                        zeit: "GT",
                    });
                } else {
                    calenderSlots.push(entry);
                }
            }
            if (calenderSlots[0] === undefined) return;

            setCalenderSlots(calenderSlots);
        }


    }, [availableSlots]);

    useEffect(() => {

        let tmpSlots = [];

        selectedDates.forEach((range) => {
            if (range[0] === undefined || range[1] === undefined) return;
            let start = range[0].format("YYYY-MM-DD");
            let end = range[1].format("YYYY-MM-DD");

            availableSlots.forEach((slot) => {
                let dateToCheck = slot.date;


                if (isDateBetween(dateToCheck, start, end)) {
                    tmpSlots.push(slot);
                }
            });
        });

        setSelectedSlots(tmpSlots);
    }, [selectedDates, availableSlots]);

    return (
        <div>
            <div className="abschnitt">
                <div style={{ margin: "10px " }}>
                    <div className="un-availble">
                        <div className="ganztag" />
                        <p>{t("Ganztag")}</p>
                    </div>
                    <div className="un-availble">
                        <div className="vormittag" />
                        <p>{t("Nur Vormittag")}</p>
                    </div>
                    <div className="un-availble">
                        <div className="nachmittag" />
                        <p>{t("Nur Nachmittag")}</p>
                    </div>
                </div>
                <div className="selector-calendar-group">
                    <div>
                        <Calendar
                            plugins={[
                                dateHighlighter(calenderSlots.map((item) => ({ date: item.date, zeit: item.zeit }))),
                            ]}
                            highlightToday={false}
                            multiple
                            range
                            onChange={handleDateChange}
                            value={selectedDates}
                            weekDays={dayNamesShort}
                            months={monthNames}
                            currentDate={initialViewDate}


                        />

                    </div>

                    <div className="slot-selector__abschnitt">
                        <h3>{t("Verfügbare Slots")}</h3>

                        {selectedSlots.length > 0 ? (
                            selectedSlots
                                .map((slot, index) => (
                                    <div key={slot.date + '-' + slot.zeit} className="slot-selection-wrapper">
                                        <input
                                            type="checkbox"
                                            id={slot.id}
                                            name={slot.id}
                                            value={slot.id}
                                            onChange={(e) => { handleSlotSelection(e, slot.id) }}

                                        />
                                        <label className="slot-selection" htmlFor={slot.id}>
                                            {formatDate(slot.date)} - {slot.zeit.charAt(0).toUpperCase() + slot.zeit.slice(1)} - {slot.kontingent === null || slot.kontingent === undefined ? 2 : slot.kontingent} {t("WS")}
                                        </label>

                                    </div>
                                ))
                        ) : (
                            <p className="no-slots">{t("kein_bereich_ausgewählt")}</p>
                        )}
                    </div>

                </div>

            </div>

        </div>
    );
}
