import ContentWrapper from '@containers/content-wrapper/ContentWrapper';
import { useDirectus } from 'react-directus';
import { useEffect, useState } from 'react';

import { UserEntries } from '@helpers/UserEntries';
import { BasicTable } from '@containers/table/BasicTable';

const User = () => {
    const { directus } = useDirectus();
    const [entriesObject, setEntriesObject] = useState(null);

    const getDataOnLoad = async () => {
        const items = await directus.items('directus_users').readByQuery();;
        const myObject = new UserEntries(items.data);
        setEntriesObject(myObject);
    };
    useEffect(() => {
        getDataOnLoad();
    }, []); // eslint-disable-line

    return <ContentWrapper title={'User'} >         {entriesObject ?
        <BasicTable entries={entriesObject} />
        : null}
    </ContentWrapper>;
};

export default User;