import ContentWrapper from '../../containers/content-wrapper/ContentWrapper';
import { useDirectus } from 'react-directus';
import { useEffect, useState } from 'react';
import 'react-data-grid/lib/styles.css';
import { AnmeldungEntries } from '../../helpers/AnmeldungEntries';
import { useTranslation } from 'react-i18next';

import ExcelExport from './components/ExcelExportButton';
import { AnmeldungenTable } from '../../containers/table/AnmeldungenTable';

const Anmeldungen = () => {
    const { directus } = useDirectus();
    const [entriesObject, setEntriesObject] = useState(null);
    const { t } = useTranslation('anmeldung');


    const getDataOnLoad = async () => {
        const items = await directus.items('anmeldungen').readByQuery(
            {
                fields: ['*', 'slots.*', 'workshops.*'],
                limit: 1000
            }
        );

        items.data.forEach((item) => {
            if (item.workshops && Array.isArray(item.workshops)) {
                const allSlotsNotNull = item.workshops.every((workshop) => workshop.slot !== null);

                if (allSlotsNotNull) {
                    item.zuteilung = 'zugeteilt';
                } else {
                    item.zuteilung = 'nicht zugeteilt';

                }
            }
        });

        const myObject = new AnmeldungEntries(items.data);
        setEntriesObject(myObject);
        console.log(myObject)
        myObject.getFields();

    };
    useEffect(() => {
        getDataOnLoad();
    }, []);

    return <ContentWrapper title={t('Anmeldungen')} exportButton={<ExcelExport data={entriesObject} />} >
        {entriesObject ?
            <AnmeldungenTable entries={entriesObject} />
            : null}
    </ContentWrapper>;
};

export default Anmeldungen;