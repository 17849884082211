import React from 'react'

import { NavLink } from 'react-router-dom'

import { PropTypes } from 'prop-types'

export default function NavItem(props) {
    const { name, permalink } = props;
    return (
        <li className="list-item">
            <NavLink className={({ isActive }) =>
                isActive ? 'is-active' : undefined
            } to={permalink}>{name}</NavLink>
        </li>
    )
}

NavItem.propTypes = {
    name: PropTypes.string.isRequired,
    permalink: PropTypes.string.isRequired
}