import { Entries, Entry } from "@helpers/Entries";

const PlzObject = new Entry([
    { key: 'plz', name: 'PLZ' },
    { key: 'ort', name: 'Ort' },
    { key: 'kanton', name: 'Kanton' },
    { key: 'hub_name', name: 'Hub' },
    { key: 'region', name: 'Region' }
]);
const getCollectionHubItems = async (directus, setCollectionHubItems) => {
    const hubItems = await directus.items('hubs').readByQuery();
    const convertedData = hubItems.data.map(item => ({
        id: item.id,
        name: JSON.parse(item.fields).name
    }));
    setCollectionHubItems(convertedData);
};

const getCollectionRegionenItems = async (directus, setCollectionRegionenItems) => {
    const hubItems = await directus.items('regionen').readByQuery();
    const convertedData = hubItems.data.map(item => ({
        id: item.id,
        name: item.name
    }));
    setCollectionRegionenItems(convertedData);
};


class PlzEntries extends Entries {
    constructor(rawData) {
        super(rawData);
        // This is the constructor method
        this.rawData = rawData;

        this.flattenedData = rawData.map((obj) => {
            const { name, fields, hub, ...rest } = obj;
            let parsedName = {};
            let parsedFields = {};

            if (name) {
                parsedName = JSON.parse(name);
            }

            if (fields) {
                parsedFields = JSON.parse(fields);
            }

            if (hub) {
                parsedFields.hub = hub.id;
                parsedFields.hub_name = JSON.parse(hub.fields).name;
            }

            return {
                ...rest,
                ...parsedName,
                ...parsedFields,
                region: obj.region?.name
            };
        });

        this.attributes = PlzObject.getAttributes();

    }

}


export { getCollectionHubItems, getCollectionRegionenItems, PlzEntries, PlzObject };