import './textfield.scss';
import '../general-input-styles.scss';
import PropTypes from 'prop-types'

const Textfield = (props) => {
    const { value, onChange, placeholder, type, className, min } = props;
    const handleChange = (e) => {
        e.preventDefault();
        onChange(e.target.value);
    }

    return <div className={'input-wrapper ' + className}><input
        min={min}
        value={value} onChange={handleChange} placeholder={placeholder} type={type} />
        <label>{placeholder}</label>
    </div>;
};

Textfield.defaultProps = {
    type: 'text'
}

Textfield.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string,
    className: PropTypes.string,
    min: PropTypes.number,
}

export default Textfield;