import React from 'react'
import './button.scss';
import PropTypes from "prop-types";

export default function GeneralButtonGroup({ buttons }) {
    return (
        <div className="button-wrapper small button-group">
            {buttons.map((button) => {
                return <button
                    className={`button small ${button.classNames}`}
                    onClick={button.handleOnClick}>
                    {button.text}
                </button>
            })}
        </div>
    );
}

GeneralButtonGroup.propTypes = {
    buttons: PropTypes.array.isRequired,
};