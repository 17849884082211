import React from 'react'

export default function NextSteps(props) {

    const { children } = props;
    return (
        <div>
            <h2>Nächste Schritte</h2>
            <p>Das sind die nächsten Schritte.</p>

            {children}
        </div>
    )
}
