import React, { useEffect, useState } from 'react'
import { useDirectus } from 'react-directus';

import SlotItem from '@components/slots/SlotItem';
import { useTranslation } from 'react-i18next';

export default function Slots(props) {
    const { slotIds, removeSlotHandler } = props;
    const { directus } = useDirectus();
    const { t } = useTranslation('anmeldung');

    const [slots, setSlots] = useState([]);

    const sortSlots = (slots) => {
        slots.sort((a, b) => {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;

            return zeitOrder[a.zeit] - zeitOrder[b.zeit];
        });

        return slots;
    }

    const zeitOrder = {
        "vormittag": 1,
        "nachmittag": 2
    };


    useEffect(() => {
        if (slotIds.length === 0) {
            setSlots([]);
            return;
        }
        directus.items('slots').readMany(slotIds).then(
            (response) => {
                const tmpSlots = sortSlots(response.data)
                setSlots(tmpSlots);
            }).catch((e) => {
                console.log(e);
            })


    }, [slotIds])


    return (
        <div>
            {slots.map((slot, index) => (
                <SlotItem slot={slot} key={slot.id} removeSlotHandler={removeSlotHandler} />
            ))}
            {slots.length === 0 && <p>{t('Keine Slots ausgewählt')}</p>}
        </div>
    )
}
