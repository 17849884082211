import React from 'react';
import { utils, writeFile } from 'xlsx'

import PropTypes from 'prop-types'

const getCurrentDateTime = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}-${hours}${minutes}`;
}

const NewsletterExcelExport = (props) => {

    const exportData = async (data) => {

        let wb = utils.book_new(),
            ws = utils.json_to_sheet(data);
        utils.book_append_sheet(wb, ws, "Newsletter")
        writeFile(wb, `bikecontrol-newsletter-${getCurrentDateTime()}.xlsx`)
    }

    const { data } = props;
    return (
        <span onClick={() => exportData(data.flattenedData)} style={{ cursor: 'pointer' }} tabIndex={0} onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
                exportData(data.flattenedData);
            }
        }}>
            <img className={'export-button'} src={process.env.PUBLIC_URL + '/assets/Import.svg'} alt="arrow-filter" />
        </span>
    );
}

NewsletterExcelExport.propTypes = {
    data: PropTypes.object.isRequired,
}

export default NewsletterExcelExport;