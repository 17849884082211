import { useEffect, useState } from 'react';
import { ActivateTFA } from '../../containers/two-factor-authentication';
import './enforce-tfa.scss';

import { useDirectus } from 'react-directus';
import { useNavigate } from 'react-router-dom';
const EnforceTfa = () => {

    const { directus } = useDirectus();
    const navigate = useNavigate();
    const getUser = async () => {
        const newMe = await directus.users.me.read({ fields: ['*', 'role.*'] });
        //if (newMe.tfa_secret === null && newMe.role.enforce_tfa) navigate('/enforce-tfa');
        if (newMe.tfa_secret !== null) navigate('/app/dashboard');
    }
    useEffect(() => {

        getUser();
    }, []) // eslint-disable-line


    return <div className="leiter-signup-page">

        <div className="enforce-tfa">
            <ActivateTFA />
        </div>
    </div>;
};

export default EnforceTfa;