import React, { useState, useEffect } from 'react';
import { format, parse } from 'date-fns';
import { Button } from '@components';
import { useDirectus } from 'react-directus';
import DatePicker from '@containers/date-picker/DatePicker';
import { useTranslation } from 'react-i18next';
import './slots-import.scss';
import formatDate from '@helpers/formatDate';
import { useError } from '../../../context/ErrorContext';
import { useNavigate, Link } from 'react-router-dom';

function DatePickerImport() {
    const [slots, setSlots] = useState([]);
    const { directus } = useDirectus();
    const [enableRegionen, setEnableRegionen] = useState(false);
    const { t } = useTranslation();

    const [selectedRegion, setSelectedRegion] = useState(null);
    const [collectionRegionenItems, setCollectionRegionenItems] = useState([]);
    const [kontingent, setKontingent] = useState(2);
    const { setErrorMessage, setSuccessMessage } = useError();
    const navigate = useNavigate();
    const getCollectionRegionenItems = async () => {
        const hubItems = await directus.items('regionen').readByQuery();
        const convertedData = hubItems.data.map(item => ({
            id: item.id,
            name: item.name
        }));
        setCollectionRegionenItems(convertedData);
    };

    useEffect(() => {
        getCollectionRegionenItems();

    }, []);

    useEffect(() => {
        directus.items('hubs').readByQuery().then(
            (response) => {
                setEnableRegionen(response.data[0].enableRegionen === 'true' ? true : false)
            }
        ).catch((e) => {
            setErrorMessage(e.message);
        })
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();

        const formatDate = (dateString) => {
            const parsedDate = parse(dateString, 'dd.MM.yy', new Date());
            const formattedDate = format(parsedDate, 'yyyy-MM-dd');
            return formattedDate;
        };

        if (slots?.length === 0) {
            setErrorMessage(t('Bitte wähle mindestens einen Slots aus.'));
            return;
        }

        const updatedData = slots.map(({ date, color }) => ({
            date: formatDate(date),
            zeit: color,
            region: selectedRegion,
            kontingent: kontingent,
        }));

        directus.items('slots')
            .createMany(updatedData)
            .then((response) => {
                setSuccessMessage(t('Slots wurden erfolgreich erstellt.'));
                navigate('/app/slots');
            })
            .catch((e) => {
                setErrorMessage(t('Fehler beim speichern: ') + e.message);
            });

    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="slot-selector-wrapper">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <DatePicker
                            slots={slots}
                            onChange={setSlots}
                        />
                        {enableRegionen ?

                            <select
                                id="selectItem"
                                value={selectedRegion}
                                onChange={(e) => setSelectedRegion(e.target.value)}
                                style={{ marginBottom: 0 }}
                            >
                                <option value="">{t('Region auswählen')}</option>
                                {collectionRegionenItems.map(item => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select> : null}
                        <div className="input-wrapper small" style={{ marginTop: 15 }}>
                            <input
                                type="number"
                                min="2"
                                max="4"
                                step="2"
                                value={kontingent}
                                placeholder={t("Kontingent")}
                                onChange={(e) => setKontingent(e.target.value)}
                            />
                        </div>
                    </div>

                    {slots?.length > 0 &&
                        <div className="slot-selector">
                            {slots?.map((row, index) => (
                                <div key={row.date + '-' + row.color} className="slot-selection-wrapper" >
                                    <div className="slot-selection">
                                        {formatDate(row.date)} - <span className="capitalize">{row.color}</span>
                                    </div>
                                </div>
                            ))}
                        </div>

                    }
                </div>

                <Button type="submit" value={t("Speichern")} className="small" />
                <Link to="/app/slots/neu" className="small">{t("zurück")}</Link>

            </form>
        </div>
    );
}

export default DatePickerImport;