import React from 'react';
import MaskedInput from './MaskedInput';
import PropTypes from 'prop-types';


export default function DateInput(props) {
    const { type, value, onChange } = props;

    const handleChange = (e) => {
        e.preventDefault();
        onChange(e.target.value);
    }
    return (
        <div className="input-wrapper small">
            <h2>{type === 'birthdate' ? 'Geburtsdatum' : 'Jahr der ersten Ausbildung'}</h2>
            {
                type === 'birthdate' ?
                    <MaskedInput
                        mask="00.00.0000"
                        placeholder="dd.mm.yyyy"
                        type="birthday"
                        value={value}
                        onChange={handleChange}
                    /> :

                    <MaskedInput
                        mask="0000"
                        placeholder="yyyy"
                        type="year"
                        value={value}
                        onChange={handleChange}
                    />
            }
        </div>
    );
}

DateInput.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};