import React from 'react';
import { utils, writeFile } from 'xlsx'
import PropTypes from "prop-types";

import { useDirectus } from 'react-directus';


const getCurrentDateTime = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}-${hours}${minutes}`;
}


function CSVExport(props) {

    const { directus } = useDirectus();

    const getSlots = async (slotIds) => {
        const tmpSlotIds = slotIds.map((slot) => { return slot.id })
        const slots = await directus.items('slots').readByQuery({
            filter: {
                id: { _in: tmpSlotIds },
            },
        });

        const preservedFields = slots.data.map((item) => {
            return {
                date: item.date,
                zeit: item.zeit,
            };
        });


        return preservedFields;
    }

    const getSlotsByIds = async (slotIds) => {
        const tmpSlotIds = slotIds.map((slot) => { return slot.slots_id })
        const slots = await directus.items('slots').readByQuery({
            filter: {
                id: { _in: tmpSlotIds },
            },
        });

        const preservedFields = slots.data.map((item) => {
            return {
                date: item.date,
                zeit: item.zeit,
            };
        });


        return preservedFields;
    }
    const getWorkshops = async (workshopIds) => {
        const workshops = await directus.items('workshops').readByQuery({
            filter: {
                id: { _in: workshopIds },
            },
        });

        const preservedFields = workshops.data.map((item) => {
            return {
                bezeichnung: item.bezeichnung,
                anzahl_schueler: item.anzahl_schueler,
                inklusion: item.inklusion
            };
        });


        return preservedFields;
    }


    const fetchSlotsForItem = async (item) => {
        if (Array.isArray(item.slots) && item.slots.length !== 0) {
            item.slots = await getSlots(item.slots);
        }
        return item;
    };

    const fetchWorkshopsForItem = async (item) => {
        if (Array.isArray(item.workshops) && item.workshops.length !== 0) {
            item.workshops = await getWorkshops(item.workshops);
        }
        return item;
    };

    // new

    const fetchAnmeldungen = async () => {
        const anmeldungen = await directus.items('anmeldungen').readByQuery({ fields: '*,slots.*, workshops.*' });

        const preservedFields = anmeldungen.data.map((item) => {

            const tmpItem = { ...item, ...JSON.parse(item.fields) }

            const finanziellerSupport = item?.finanziellerSupport ? { ...JSON.parse(item.finanziellerSupport) } : {}

            let returnObj = {
                id: tmpItem.id,
                schule: tmpItem.schule,
                ort: tmpItem.ort,
                plz: tmpItem.plz,
                adresse: tmpItem.adresse,
                vorname: tmpItem.vorname,
                nachname: tmpItem.nachname,
                telefon: tmpItem.telefon,
                email: tmpItem.email,
                alternative_termine: tmpItem.alternative_termine,
                status: tmpItem.status,
                date_created: tmpItem.date_created,
                hub: tmpItem.hub?.name,
                slots: tmpItem.slots,
                anzahl_slots: tmpItem.slots.length,
                workshops: item.workshops,
                anzahl_workshops: item.workshops.length,
                inklusion: item.workshops.some((obj) => { return obj.inklusion === true }),

            };

            if (item?.finanziellerSupport !== undefined) {
                returnObj = {
                    ...returnObj,
                    fixerBeitrag: finanziellerSupport?.fixerBeitrag,
                    supportBetrag: finanziellerSupport.supportBetrag,
                    proKind: finanziellerSupport.proKind,
                    anzahl_schueler: item.workshops.reduce((acc, obj) => acc + (parseInt(obj.anzahl_schueler) || 0), 0),
                    nochNicht: finanziellerSupport.nochNicht,
                }
            }

            return returnObj;
        });


        return preservedFields;
    }

    async function processAnmeldungen(anmeldungen) {
        const processedAnmeldungen = [];

        const combinedSlotsAndAnmeldungen = [];

        for (const item of anmeldungen) {
            if (item.slots && Array.isArray(item.slots) && item.slots.length > 0) {
                const fetchedSlots = await getSlotsByIds(item.slots);

                item.slots = fetchedSlots.map(slot => ({
                    slotDatum: slot.date,
                    slotZeit: slot.zeit
                }));

                item.slots.forEach(slot => {
                    combinedSlotsAndAnmeldungen.push({
                        id: item.id,
                        schule: item.schule,
                        ort: item.ort,
                        plz: item.plz,
                        adresse: item.adresse,
                        alternative_termine: item.alternative_termine,
                        anzahl_slots: item.slots.length,
                        slotDatum: slot.slotDatum,
                        slotZeit: slot.slotZeit
                    });
                });
            } else {
                item.slots = [];
            }

            processedAnmeldungen.push(item);
        }
        return { processedAnmeldungen, combinedSlotsAndAnmeldungen };
    }

    const newExportData = async () => {
        const anmeldungen = await fetchAnmeldungen();

        const overview = anmeldungen.map(obj => ({ ...obj }));
        const anmeldungenMitWorkshops = anmeldungen.map(obj => ({ ...obj }));

        overview.forEach(obj => {
            delete obj.workshops;
            delete obj.slots;
        })

        const slotsTab = [];

        anmeldungen.forEach((item) => {
            if (item.slots && Array.isArray(item.slots)) {
                item.slots.forEach((slot, index) => {
                    const transformedItem = {
                        ...item,
                        slotDatum: slot.slotDatum,
                        slotZeit: slot.slotZeit,
                    };

                    slotsTab.push(transformedItem);
                });
            } else {
                slotsTab.push(item);
            }
        });

        processAnmeldungen(anmeldungen).then(({ processedAnmeldungen, combinedSlotsAndAnmeldungen }) => {
            console.log(processedAnmeldungen);
            console.log(combinedSlotsAndAnmeldungen);

            processedAnmeldungen.forEach(item => {
                if (item.slots && Array.isArray(item.slots)) {
                    item.slots.forEach((slot, index) => {
                        item[`slotDatum${index + 1}`] = slot.slotDatum;
                        item[`slotZeit${index + 1}`] = slot.slotZeit;

                    });
                }
                delete item.slots;
                delete item.workshops;
            })

            anmeldungenMitWorkshops.forEach(item => {
                if (item.workshops && Array.isArray(item.workshops)) {
                    item.workshops.forEach((workshop, index) => {
                        item[`workshopBezeichnung${index + 1}`] = workshop.bezeichnung;
                        item[`workshopInklusion${index + 1}`] = workshop.inklusion;
                        item[`workshopAnzahlSuS${index + 1}`] = workshop.anzahl_schueler;

                    });
                }
                delete item.slots;
                delete item.workshops;
            })


            let wb = utils.book_new(),
                ws1 = utils.json_to_sheet(overview),
                ws2 = utils.json_to_sheet(processedAnmeldungen),
                ws3 = utils.json_to_sheet(anmeldungenMitWorkshops),
                ws4 = utils.json_to_sheet(combinedSlotsAndAnmeldungen);
            utils.book_append_sheet(wb, ws1, "Übersichts")
            utils.book_append_sheet(wb, ws2, "Anmeldungen und Slots ")
            utils.book_append_sheet(wb, ws3, "Anmeldungen und Workshops ")

            utils.book_append_sheet(wb, ws4, "Slots")

            writeFile(wb, `bikecontrol-anmeldungen-${getCurrentDateTime()}.xlsx`)
        });

    }

    const exportData = async (data) => {

        const anmeldungenFields = data.map((item) => {
            return {
                id: item.id,
                schule: item.schule,
                ort: item.ort,
                plz: item.plz,
                adresse: item.adresse,
                vorname: item.vorname,
                nachname: item.nachname,
                telefon: item.telefon,
                email: item.email,
                anzahl_workshops: item.anzahl_klassen,
                alternative_termine: item.alternative_termine,
                status: item.status,
                date_created: item.date_created,
                hub: item.hub?.name,
                slots: item.slots,
            };
        });

        const anmeldungenFields2 = data.map((item) => {
            return {
                id: item.id,
                schule: item.schule,
                ort: item.ort,
                plz: item.plz,
                adresse: item.adresse,
                vorname: item.vorname,
                nachname: item.nachname,
                telefon: item.telefon,
                email: item.email,
                anzahl_workshops: item.anzahl_klassen,
                alternative_termine: item.alternative_termine,
                status: item.status,
                date_created: item.date_created,
                hub: item.hub?.name,
                workshops: item.workshops,
            };
        });

        const supportFields = data
            .filter((item) => {
                const finanziellerSupportFields = (item.finanziellerSupport !== undefined && item.finanziellerSupport !== '' ? JSON.parse(item.finanziellerSupport) : null);

                //const { fixerBeitrag, proKind, nochNicht, keinBeitrag } = JSON.parse(item.finanziellerSupport);
                const keinBeitrag = finanziellerSupportFields?.keinBeitrag;
                return !keinBeitrag

            })
            .map((item) => {

                const finanziellerSupportFields = (item.finanziellerSupport !== undefined && item.finanziellerSupport !== '' ? JSON.parse(item.finanziellerSupport) : null);
                return {
                    id: item.id,
                    schule: item.schule,
                    ort: item.ort,
                    plz: item.plz,
                    adresse: item.adresse,
                    vorname: item.vorname,
                    nachname: item.nachname,
                    telefon: item.telefon,
                    email: item.email,
                    anzahl_workshops: item.anzahl_klassen,
                    slots: item.slots,
                    fixerBeitrag: finanziellerSupportFields?.fixerBeitrag ? 'ja' : 'nein',
                    proKind: finanziellerSupportFields?.proKind ? 'ja' : 'nein',
                    nochNicht: finanziellerSupportFields?.nochNicht ? 'ja' : 'nein',
                    supportBetrag: finanziellerSupportFields?.supportBetrag,

                };
            });


        const updatedAnmeldungenFields = await Promise.all(
            anmeldungenFields.map(fetchSlotsForItem)
        );

        const updatedAnmeldungenFields2 = await Promise.all(
            anmeldungenFields2.map(fetchWorkshopsForItem)
        );


        const originalData = updatedAnmeldungenFields;

        const slotsData = [];

        originalData.forEach((item) => {
            if (item.slots && Array.isArray(item.slots)) {
                item.slots.forEach((slot, index) => {
                    const transformedItem = {
                        ...item, // Copy all fields from the original item
                        slotDatum: slot.date, // Add individual slot fields
                        slotZeit: slot.zeit,
                    };

                    slotsData.push(transformedItem);
                });
            } else {
                slotsData.push(item); // If no slots, add the original item as-is
            }
        });

        updatedAnmeldungenFields.forEach(item => {
            if (item.slots && Array.isArray(item.slots)) {
                item.slots.forEach((slot, index) => {
                    item[`slotDatum${index + 1}`] = slot.date;
                    item[`slotZeit${index + 1}`] = slot.zeit;

                });
            }
            delete item.slots;
        })

        updatedAnmeldungenFields2.forEach(item => {
            if (item.workshops && Array.isArray(item.workshops)) {
                item.workshops.forEach((workshop, index) => {
                    item[`workshopBezeichnung${index + 1}`] = workshop.bezeichnung;
                    item[`workshopInklusion${index + 1}`] = workshop.inklusion;

                });
            }
            delete item.workshops;
        })



        let wb = utils.book_new(),
            ws = utils.json_to_sheet(updatedAnmeldungenFields),
            ws1 = utils.json_to_sheet(updatedAnmeldungenFields2),
            ws2 = utils.json_to_sheet(slotsData),
            ws3 = utils.json_to_sheet(supportFields);
        utils.book_append_sheet(wb, ws, "Anmeldungen")
        utils.book_append_sheet(wb, ws1, "Anmeldungen mit WS")
        utils.book_append_sheet(wb, ws2, "Slots")
        utils.book_append_sheet(wb, ws3, "Finanzieller Support")

        writeFile(wb, `bikecontrol-anmeldungen-${getCurrentDateTime()}.xlsx`)

    }



    const { data } = props;
    return (
        <div
            tabIndex={0}
            onClick={() => newExportData()}
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                    exportData(data.flattenedData);
                }
            }}
            style={{ cursor: "pointer" }}
        >
            <img
                className={"export-button"}
                src={process.env.PUBLIC_URL + "/assets/Import.svg"}
                alt="arrow-filter"
            />
        </div>
    );
}

CSVExport.propTypes = {
    data: PropTypes.object.isRequired,
};

export default CSVExport;