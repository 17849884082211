import React, { useState, useEffect } from 'react'
import { Modal } from '@containers/modal/modal'
import { useDirectus } from 'react-directus';


import DeactivateTFA from '../components/DeactivateTFA';
import ActivateTFA from '../components/ActivateTFA';

export default function TFAModal() {
    const { directus } = useDirectus();

    const [twoFA, set2FA] = useState(false);

    const getUser = async () => {
        const me = await directus.users.me.read();
        console.log(me.tfa_secret)
        if (me.tfa_secret != null) {
            set2FA(true);
        }
    }

    useEffect(() => {
        getUser();
    }, [])


    return (
        <Modal title={twoFA ? '2FA deaktivieren' : 'FA aktivieren'}>

            {
                twoFA
                    ?
                    <DeactivateTFA />
                    :
                    <ActivateTFA />
            }

        </Modal>

    )
}