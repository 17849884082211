import './leiter-signup.scss';
import SignupKalender from './components/SignupKalender';

const LeiterSignup = () => {
    const queryString = window.location.search;

    // Create a URLSearchParams object to parse the query string
    const urlParams = new URLSearchParams(queryString);

    // Access the 'uuid' parameter using the get method
    const uuid = urlParams.get('uuid');
    const id = urlParams.get('id');
    return <div className="leiter-signup-page">

        <div>

            <SignupKalender uuid={uuid} id={id} />
        </div>
    </div>;
};



export default LeiterSignup;