import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { format, parse } from 'date-fns';
import { useDirectus } from 'react-directus';
import { useNavigate } from 'react-router-dom';
import './slots-import.scss';
import { useError } from '../../../context/ErrorContext';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function SlotSelection({ data }) {
    const { t } = useTranslation();

    function formatDate(dateString) {
        const date = new Date(dateString);

        const weekdays = [
            t('So'),  // Sunday
            t('Mo'),  // Monday
            t('Di'),  // Tuesday
            t('Mi'),  // Wednesday
            t('Do'),  // Thursday
            t('Fr'),  // Friday
            t('Sa'),  // Saturday
        ];

        const dayOfWeek = weekdays[date.getDay()];

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const formattedDate = `${dayOfWeek}, ${day}.${month}.${year}`;

        return formattedDate;
    }

    return (
        <div className="slot-selector">
            {data.map((row, index) => (
                <div key={row.Datum + '-' + row.Zeitfenster} className="slot-selection-wrapper">
                    <div className="slot-selection">
                        {formatDate(row.Datum)} - {row.Zeitfenster === 'vormittag' ? t('Vormittag') : t('Nachmittag')}
                    </div>
                </div>
            ))}
        </div>
    );
}


function CSVReader() {
    const { directus } = useDirectus();
    const navigate = useNavigate();
    const { setErrorMessage, setSuccessMessage } = useError();
    const { t } = useTranslation();

    const [csvData, setCSVData] = useState(null);
    const [collectionRegionenItems, setCollectionRegionenItems] = useState([]);

    useEffect(() => {
        getCollectionRegionenItems();
    }, []);

    const getCollectionRegionenItems = async () => {
        try {
            const hubItems = await directus.items('regionen').readByQuery();
            setCollectionRegionenItems(hubItems.data.map(item => ({ id: item.id, name: item.name })));
        } catch (error) {
            console.error('Error fetching region items:', error);
            // Handle error appropriately
        }
    };

    const getIdFromRegionName = (name) => {
        const region = collectionRegionenItems.find(item => item.name === name);
        return region?.id || null;
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            delimiter: ';',
            complete: (results) => {
                const { data } = results;
                try {
                    const manipulatedData = processCSVData(data);
                    setCSVData(manipulatedData);
                } catch (error) {
                    console.error('Error processing CSV data:', error);
                    setErrorMessage('Fehler beim Verarbeiten der CSV-Datei.');
                }
            },
        });
    };

    const processCSVData = (data) => {
        return data.reduce((acc, row) => {
            if (!row.datum || !row.zeitfenster) return acc;

            const formattedDate = formatDate(row.datum);
            if (!formattedDate) return acc;

            const newRow = {
                Datum: formattedDate,
                Zeitfenster: row.zeitfenster === 'VM' ? 'vormittag' : 'nachmittag',
                Region: getIdFromRegionName(row.region),
                Kontingent: row.kontingent || 2,
            };

            if (row.zeitfenster === 'GT') {
                acc.push({ ...newRow, Zeitfenster: 'vormittag' }, { ...newRow, Zeitfenster: 'nachmittag' });
            } else {
                acc.push(newRow);
            }
            return acc;
        }, []);
    };

    const formatDate = (dateString) => {
        const regexFourDigitYear = /^(\d{2})\.(\d{2})\.(\d{4})$/;
        const regexTwoDigitYear = /^(\d{2})\.(\d{2})\.(\d{2})$/;

        if (regexFourDigitYear.test(dateString)) {
            return format(parse(dateString, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
        } else if (regexTwoDigitYear.test(dateString)) {
            return format(parse(dateString, 'dd.MM.yy', new Date()), 'yyyy-MM-dd');
        } else {
            console.log('Invalid date format');
            return null;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const updatedData = csvData.map(({ Datum, Zeitfenster, Kontingent, Region }) => ({
                date: Datum,
                zeit: Zeitfenster,
                kontingent: Kontingent,
                region: Region,
            }));

            await directus.items('slots').createMany(updatedData);
            setSuccessMessage('Slots wurden erfolgreich importiert.');
            navigate('/app/slots');
        } catch (error) {
            setErrorMessage('Fehler beim Speichern: ' + error.message);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                {!csvData ? (
                    <div className="file-input">
                        <input type="file" accept=".csv" onChange={handleFileChange} className="import-csv-input" />
                        <p>{t('UploadCSVFile')}</p>
                    </div>
                ) : (
                    <div>
                        <SlotSelection data={csvData} />
                        <div className='button-wrapper small button-group'>
                            <input value={t('Import')} type='submit' />
                            <button className='button button--delete small' onClick={() => setCSVData(null)}>
                                {t('Delete')}
                            </button>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
}

SlotSelection.propTypes = {
    data: PropTypes.array.isRequired,
};

export default CSVReader;
