import React, { useEffect, useState } from 'react'
import './kalender.scss'

import { useDirectus } from 'react-directus';
import { getDataOnLoad } from '@helpers';
import AnmeldungItem from './AnmeldungItem';
const collection = 'anmeldungen';
const fields = ['*', 'slots.slots_id'];

export default function Kalender() {

    const { directus } = useDirectus();
    const [anmeldungen, setAnmeldungen] = useState([]);

    useEffect(() => {
        getDataOnLoad(collection, fields, directus).then(
            (response) => {
                const tmpData = [];
                response.data.map((anmeldung) => {
                    tmpData.push({
                        id: anmeldung.id,
                        ...JSON.parse(anmeldung.fields),
                        slots: anmeldung.slots
                    })
                })
                setAnmeldungen(tmpData)
            }
        ).catch((e) => {
            console.log(e)
        }
        );
    }, []);

    return (
        <div className="kalender">
            {anmeldungen.map((anmeldung) => (
                <AnmeldungItem anmeldung={anmeldung} key={anmeldung.id} />)
            )}

        </div>
    )
}
