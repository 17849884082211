
import './login.scss';
import ResetPassword from '../../containers/reset-password/ResetPassword';

const PasswordReset = (props) => {

    const { invitation } = props;

    return <div className="login-page">
        <ResetPassword invitation={invitation} />
    </div>;
};

export default PasswordReset;