import { Entry } from '@helpers/Entries';

const parseFields = (fields) => {
    let parsedFields = null;
    if (fields) {
        try {
            parsedFields = { ...JSON.parse(fields) }
        } catch (error) {
            // Handle the JSON parsing error, if necessary
        }
    }

    return parsedFields;
}

class RegionenEntries {
    constructor(rawData) {
        this.rawData = rawData;

        this.flattenedData = rawData.map((obj) => {
            return {
                name: obj.name,
                id: obj.id,
                hub: parseFields(obj.hub?.fields)?.name,
            };
        });

        this.attributes = RegionenObject.getAttributes();

    }

    getData() {
        return this.flattenedData;
    }

    getTableColumns(filter) {
        if (filter === undefined) {
            return this.attributes;
        }

        return this.attributes.filter((item) => {
            return filter.includes(item.key);
        });
    }
}

const RegionenObject = new Entry([
    { key: 'name', name: 'Name' },
    { key: 'hub', name: 'Hub' }
]);

export { RegionenEntries, RegionenObject };