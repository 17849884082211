import ContentWrapper from '@containers/content-wrapper/ContentWrapper';
import { useDirectus } from 'react-directus';
import { useEffect, useState } from 'react';

import { BasicTable } from '@containers/table/BasicTable';

import { NewsletterEntries, NewsletterExcelExport } from '../helpers';

import { getDataOnLoad } from '@helpers/general-helpers';


const collectionName = 'newsletter_signups';
const fields = null;

const Newsletter = () => {
    const { directus } = useDirectus();
    const [collection, setCollection] = useState(null);

    useEffect(() => {

        getDataOnLoad(collectionName, fields, directus)
            .then((items) => {
                const newsletterItems = new NewsletterEntries(items.data);
                setCollection(newsletterItems);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                // TODO: Handle error
            });
    }, []);

    return <ContentWrapper title={'Newsletter'} exportButton={<NewsletterExcelExport data={collection} />}>
        {collection ?
            <BasicTable entries={collection} />
            : null}
    </ContentWrapper>;
};

export default Newsletter;