import ContentWrapper from '@containers/content-wrapper/ContentWrapper';
import UiBlock from '@containers/ui-block/UiBlock';
import AddUser from '../containers/AddUser';
const NewUser = (props) => {
    const { isEditPage } = props;

    return <ContentWrapper title={isEditPage ? 'User bearbeiten' : 'User einladen'} addButton={false}>
        <UiBlock text={isEditPage ? 'Hier kannst du einen User bearbeiten.' : 'Hier kannst du einen neuen User erfassen. Definiere Name, Vorname und Email-Adresse. Die Person wird eine Email mit einem Einladungslink erhalten und kann das Passwort setzen.'} title={isEditPage ? 'User bearbeiten' : 'Neue User erfassen'}>
            <AddUser isEditPage={isEditPage} /></UiBlock>
    </ContentWrapper>;
};

export default NewUser;