import React, { useMemo, memo, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Define CustomLoader component outside of SlotsTable
const CustomLoader = () => <div>Loading...</div>;

const EmptyComponent = () => <div>No Data Available</div>;

const SlotsTable = memo((props) => {
    const { entries, filter, onDelete } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const cell = useCallback((row) => (
        <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => onDelete(row)}
            aria-label={t('Eintrag löschen')}
        >
            {t('Eintrag löschen')}
        </span>
    ), [onDelete, t]);

    // Memoize columns to avoid recalculations on every render
    const columns = useMemo(() => [
        ...entries.getTableColumns(filter),
        {
            name: t('Löschen'),
            cell, // Use the memoized cell function
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ], [entries, filter, t, cell]);

    const formatDate = (row) => {
        const date = new Date(row.date);
        return date.toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: '2-digit' });
    };
    if (columns[0]) {
        columns[0].format = formatDate;
    }

    const handleRowClick = (row) => {
        navigate(`bearbeiten/${row.id}`);
    };

    return (
        <DataTable
            columns={columns}
            data={entries.getData()}
            highlightOnHover
            keyField="id"
            defaultSortField="id"
            defaultSortDesc
            responsive
            onRowClicked={handleRowClick}
            progressPending={entries.loading}
            progressComponent={<CustomLoader />}
            noDataComponent={<EmptyComponent />}
        />
    );
});

SlotsTable.propTypes = {
    entries: PropTypes.shape({
        getTableColumns: PropTypes.func.isRequired,
        getData: PropTypes.func.isRequired,
        loading: PropTypes.bool,
    }).isRequired,
    filter: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export { SlotsTable };
